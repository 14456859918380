import { useNavigate } from 'react-router-dom';
import AppBar from '../../../components/appBar';
import Page from '../../../components/page';
import { LoadingMask, showConfirm, showToast } from '../../../utils/utils';
import { apiLogout } from '../../../api';
import main, { AppLocalKey } from '../../../main';
import { ProtocolType } from '../../../utils/dict';

export default function SettingPage() {
  const navigate = useNavigate();

  async function handleGoLogin() {
    let modalRes = await showConfirm({
      content: '确认退出登录吗？',
    });
    if (!modalRes) return;

    LoadingMask.show('正在退出...');
    try {
      let res = await apiLogout();
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '退出登录成功');
        main.user = undefined;
        main.currentEm = undefined;
        main.loginKey = undefined;
        localStorage.removeItem(AppLocalKey.loginToken);
        navigate('/login', { replace: true });
      } else {
        showToast(data.msg ?? '退出失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '退出失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page className="d-flex-c bg-gray" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="设置" />}>
      <div
        className="mt-10 mb-10 pt-10 pb-10 pl-20 pr-20 bg-white d-flex-r ai-center"
        onClick={() => navigate('/em-info')}
      >
        <div className="flex-1">设备资料</div>
        <img className="w-12" src="/pic/icon_arrow_right.png" alt="" />
      </div>
      {/* <div
        className="pt-10 pb-10 pl-20 pr-20 bg-white d-flex-r ai-center border-bottom"
        onClick={() => navigate('/set-password')}
      >
        <div className="flex-1">操作密码设置</div>
        <img className="w-12" src="/pic/icon_arrow_right.png" alt="" />
      </div> */}
      <div
        className="pt-10 pb-10 pl-20 pr-20 bg-white d-flex-r ai-center border-bottom"
        onClick={() => navigate('/protocol', { state: ProtocolType.userAgreement })}
      >
        <div className="flex-1">用户协议</div>
        <img className="w-12" src="/pic/icon_arrow_right.png" alt="" />
      </div>
      <div
        className="pt-10 pb-10 pl-20 pr-20 bg-white d-flex-r ai-center border-bottom"
        onClick={() => navigate('/protocol', { state: ProtocolType.privacyAgreement })}
      >
        <div className="flex-1">隐私政策</div>
        <img className="w-12" src="/pic/icon_arrow_right.png" alt="" />
      </div>
      <div
        className="pt-10 pb-10 pl-20 pr-20 bg-white d-flex-r ai-center border-bottom"
        onClick={() => navigate('/protocol', { state: ProtocolType.userManual })}
      >
        <div className="flex-1">用户使用手册</div>
        <img className="w-12" src="/pic/icon_arrow_right.png" alt="" />
      </div>
      <div className="mt-10 pt-10 pb-10 pl-20 pr-20 bg-white" onClick={() => handleGoLogin()}>
        <div className="text-center text-color-red">退出登录</div>
      </div>
    </Page>
  );
}
