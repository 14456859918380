import React from 'react';
interface Props {
  value: boolean;
  className?: string;
  disabled?: boolean;
  checkedImg?: string;
  defaultImg?: string;
  onChange?: (v?: boolean) => void;
}
export default function RadioBox({ value = false, onChange, disabled, className, checkedImg, defaultImg }: Props) {
  function handleChange() {
    if (disabled) return;
    if (onChange) {
      onChange(!value);
    }
  }
  let checked = checkedImg ? checkedImg : 'checked';
  let defaultUrl = defaultImg ? defaultImg : 'default';
  return (
    <div className={`m-checkbox ${className}`} onClick={handleChange}>
      <img
        className="m-size"
        src={`/pic/icon_radio_${disabled ? 'disabled' : value ? checked : defaultUrl}.png`}
        alt=""
      />
    </div>
  );
}
