import { useState } from 'react';
import { apiEditEm, apiGeEmInfo } from '../../../api';
import AppBar from '../../../components/appBar';
import Page from '../../../components/page';
import main from '../../../main';
import { LoadingMask, showConfirm, showToast } from '../../../utils/utils';

export default function EmInfoPage() {
  const [currentEm, setCurrentEm] = useState(main.currentEm);
  async function handleEditDesc() {
    let old = currentEm?.userRemark;
    let desc = old;
    let modalRes = await showConfirm({
      title: '修改描述',
      content: (
        <div className="border bw-100 p-5">
          <input
            className="bw-100"
            defaultValue={old}
            placeholder="请输入设备描述"
            onChange={(e) => {
              desc = e.target.value;
            }}
          />
        </div>
      ),
    });
    if (!modalRes || old === desc) return;
    LoadingMask.show('正在设置...');
    try {
      let res = await apiEditEm(currentEm?.id!, desc, currentEm?.defaultStatus === 'Y');
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '设置成功');
        let userInfoRes = await apiGeEmInfo();
        let userInfoData = userInfoRes.data;
        if (userInfoData.code === 200) {
          main.currentEm = userInfoData.data;
          setCurrentEm(userInfoData.data);
        }
      } else {
        showToast(data.msg ?? '设置失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '设置失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="设备资料" />}>
      <div className="h-10 bg-gray"></div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r ai-center jc-between bg-white border-bottom">
        <div>设备号</div>
        <div className="text-color-9">{currentEm?.emNumber}</div>
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r ai-center jc-between bg-white border-bottom">
        <div>绑定手机号</div>
        <div className="text-color-9">{main.user?.phonenumber}</div>
      </div>
      <div
        className="pt-10 pb-10 pl-20 pr-20 d-flex-r ai-center jc-between bg-white border-bottom"
        onClick={handleEditDesc}
      >
        <div>设备描述</div>
        <div className="d-flex-r ai-center">
          <div className="text-color-9">{currentEm?.userRemark}</div>
          <img className="ml-10 w-14" src="/pic/icon_write.png" alt="" />
        </div>
      </div>
    </Page>
  );
}
