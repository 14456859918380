import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetComplaintList } from '../../../api';
import AppBar from '../../../components/appBar';
import NoData from '../../../components/noData';
import Page from '../../../components/page';
import { ComplaintStatus } from '../../../utils/dict';
import { showToast } from '../../../utils/utils';
const statusOptions = [
  { label: '已完成', color: 'green' },
  { label: '处理中', color: 'p' },
  { label: '已取消', color: 'red' },
  { label: '待处理', color: 'origin' },
];
export default function ComplaintListPage() {
  const navigate = useNavigate();
  const [list] = useState([
    { label: '投诉时间', value: 'createTime' },
    { label: '投诉类型', value: 'workOrderName' },
    { label: '投诉原因', value: 'workOrderContent' },
    { label: '处理结果', value: 'status' },
  ]);
  const [dates, setDates] = useState([]);
  const colorText = (text: string) => {
    let n = statusOptions.filter((item) => item.label === ComplaintStatus.getTypeStr(text));
    if (!n) return;
    return <div className={`text-color-${n[0]?.color}`}>{n[0]?.label}</div>;
  };

  useEffect(() => {
    getComplaintList();
  }, []); // eslint-disable-line

  const getComplaintList = async () => {
    try {
      var res = await apiGetComplaintList();
      var data = res.data;
      if (data.code === 200) {
        setDates(data?.rows ?? []);
      } else {
        showToast(data.msg ?? '请求失败');
      }
    } catch (e: any) {
      showToast(e.message || '请求失败');
    }
  };
  const handleChangeRoute = (item: { id: number | string }) => {
    navigate(`/complaint-detail`, { state: item.id });
  };
  return (
    <Page
      className="d-flex-c bg-white"
      contentClassName="flex-1 overflow-y h-1 p-20"
      appBar={<AppBar title="投诉历史记录" />}
    >
      {!dates.length && (
        <div className="mt-100">
          <NoData tips="暂无数据" />
        </div>
      )}
      {dates.map((item, idx) => (
        <div key={idx} className="d-flex-r ai-center mb-20 border-bottom" onClick={() => handleChangeRoute(item)}>
          <div className="flex-1 text-color-0">
            {list.map((listItem, listIdx) => (
              <div key={listItem.label + listIdx} className="d-flex-r mb-10">
                <span className="text-color-6 w-60">{listItem.label}</span>
                <span className="w-1 ml-10 flex-1 omit-text-1">
                  {listItem.label === '处理结果' ? colorText(item[listItem.value]) : item[listItem.value]}
                </span>
              </div>
            ))}
          </div>
          <img className="icon-20 ml-10" src="/pic/icon_arrow_right.png" alt="" />
        </div>
      ))}
    </Page>
  );
}
