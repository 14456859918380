import { useState } from 'react';
import Button from '../../../components/button';
import { CouponExpiredType, CouponType } from '../../../utils/dict';
import { formatNumber } from '../../../utils/utils';
import dayjs from 'dayjs';
import RadioBox from '../../../components/radioBox';

interface Props {
  selects: string[];
  list: Array<Coupon>;
  onOk: (value: any) => void;
}
export default function SelectCouponDialog({ selects, list, onOk }: Props) {
  const [select, setSelect] = useState<Array<string>>([...selects]);

  function handleSelect(coupon: Coupon) {
    // 如果新选了叠加，就把之前的不是叠加的全去掉、
    // 如果新选了不叠加，就只保留不叠加的
    let _tmp: string[] = [];

    // 先处理不叠加的 只保留最新的
    if (coupon.isOverlay !== 'Y') {
      _tmp = [coupon.id!];
    } else {
      // 再处理叠加的
      for (var item of list) {
        // 如果是已选中的优惠券 并且是叠加的，则还放到选中组
        if (select.includes(item.id!) && item.isOverlay === 'Y') {
          _tmp.push(item.id!);
        }
      }
      if (!_tmp.includes(coupon.id!)) {
        // 如果自己不在
        // 保留完所有叠加的之后再把 自己放进去
        _tmp.push(coupon.id!);
      } else {
        // 如果自己在
        // 就把自己从选中组去掉
        _tmp.splice(_tmp.indexOf(coupon.id!), 1);
      }
    }
    setSelect(_tmp);
  }
  function handleConfirm() {
    onOk(select);
  }
  return (
    <div className="bg-white border-radius-tl-10 border-radius-tr-10 pt-10">
      <div className="d-flex-r ai-center jc-between pl-10 pr-10 pb-10">
        <img className="icon-20 view-opacity-0" src="/pic/icon_close_black.png" alt="" />
        <div className="font-size-16">选择优惠券</div>
        <img className="icon-20" src="/pic/icon_close_black.png" alt="" onClick={() => onOk(false)} />
      </div>
      <div className="d-flex-c ai-center pt-10 pl-20 pr-20 pb-10">
        <div className="flex-1  bw-100">
          {list.map((item) => {
            let amountType: string = item.couponType === CouponType.flow ? 'G' : '元';
            let amount: string = (
              item.couponType === CouponType.flow ? item.addFlow ?? '' : formatNumber(item.reduceAmount ?? 0)
            ).toString();

            let expiredStr: string =
              item.expireType !== CouponExpiredType.instant
                ? `${dayjs(item.validEndTime!).format('YYYY/MM/DD')}失效`
                : `购买后${item.validDays}天内可用`;
            let _isOverlay = item.isOverlay === 'Y' ? '可叠加' : '不可叠加';
            return (
              <div
                key={item.id}
                className="d-flex-r ai-center mt-10 pb-10 border-bottom"
                onClick={() => handleSelect(item)}
              >
                <div className="flex-1">
                  <div className="font-widget-600">{item.couponName}</div>
                  <div className="font-size-12 text-color-9 mt-5">{expiredStr}</div>
                </div>
                <div className="mr-10 font-size-12 text-color-9">{_isOverlay}</div>
                <div className="mr-20 text-color-red">
                  {amount}
                  {amountType}
                </div>
                <RadioBox value={select.includes(item.id ?? '')} />
              </div>
            );
          })}
        </div>
        <div className="bw-100 mt-60">
          <Button type="primary" size="md" long onClick={handleConfirm}>
            确认选择
          </Button>
        </div>
      </div>
    </div>
  );
}
