interface props {
  list: string[];
  activeItem?: string;
  onCancel: (v: string) => void;
}
export default function SelectDialogPage({ list, activeItem, onCancel }: props) {
  return (
    <div className="select-dialog bw-100 pos-a d-shadow pt-10 pl-10 pr-20 border-radius-4">
      {list.map((item, idx) => (
        <div key={idx} className="mb-10 d-flex-r ai-center" onClick={() => onCancel(item)}>
          <img
            className={`icon-20 ${activeItem !== item && 'view-opacity-0'}`}
            src="/pic/icon_radio_checked.png"
            alt=""
          />
          <span className={`ml-5 ${activeItem === item && 'text-color-p'}`}>{item}</span>
        </div>
      ))}
    </div>
  );
}
