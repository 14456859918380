import React, { useRef, useState } from 'react';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import { LoadingMask, showConfirm, showToast } from '../../../utils/utils';
import { apiCreateAndEditPwd, apiGetCommonCaptcha } from '../../../api';
import main from '../../../main';

export default function SetPasswordPage() {
  const navigate = useNavigate();
  const [telPhone, setTel] = useState(main.user?.phonenumber!);
  const [captcha, setCaptcha] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);
  const [twoPassword, setTwoPassword] = useState('');
  const [twoPasswordShow, setTwoPasswordShow] = useState(false);
  const timeRef = useRef<{ time: number; timer?: NodeJS.Timer }>({ time: 60, timer: undefined });
  const [captchaTime, setCaptchaTime] = useState(60);
  const [timing, setTiming] = useState(false);

  function startTimer() {
    let jsTimer: NodeJS.Timer = setInterval(() => {
      let t = timeRef.current.time - 1;
      if (t <= 0) {
        setTiming(false);
        clearInterval(timeRef.current.timer);
        timeRef.current.timer = undefined;
      }
      timeRef.current.time = t;
      setCaptchaTime(t);
    }, 1000);
    timeRef.current.timer = jsTimer;
  }

  async function handleGetCaptcha() {
    if (timeRef.current.timer) return;
    if (!/^1[0-9]{10}$/.test(telPhone)) {
      return showToast('请输入正确的手机号');
    }
    try {
      LoadingMask.show('正在发送...');
      let res = await apiGetCommonCaptcha(telPhone);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '验证码已发送');
        setTiming(true);
        setCaptchaTime(60);
        timeRef.current.time = 60;
        startTimer();
      } else {
        showToast(data.msg || '发送失败');
      }
    } catch (e: any) {
      showToast(e.message || '发送失败');
    } finally {
      LoadingMask.hide();
    }
  }

  function checkPwd(t?: string) {
    if (!t) return false;
    if (t.length !== 6) return false;
    if (parseInt(t).toString() !== t) return false;
    return true;
  }

  async function handleUpdate() {
    let exp = /^1[3456789]\d{9}$/;
    if (!exp.test(telPhone)) {
      return showToast('手机号格式不正确');
    }
    if (!captcha) {
      return showToast('请输入验证码');
    }
    if (!checkPwd(password)) {
      return showToast('请输入6位数字密码');
    }
    if (!checkPwd(twoPassword)) {
      return showToast('请确认6位数字密码');
    }
    if (password !== twoPassword) {
      return showToast('两次密码不一致');
    }
    let modalRes = await showConfirm({ title: '提示', content: '确定修改密码吗？' });
    if (!modalRes) return;
    try {
      LoadingMask.show('正在修改...');
      let res = await apiCreateAndEditPwd(password, captcha, telPhone);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '修改成功');
        navigate(-1);
      } else {
        showToast(data.msg || '修改失败');
      }
    } catch (e: any) {
      showToast(e.message || '修改失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page appBar={<AppBar title="操作密码设置" />}>
      <div className="h-10 bg-gray"></div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r border-bottom ai-center">
        <div className="minw-100">手机号</div>
        <input
          className="flex-1"
          value={telPhone}
          disabled
          placeholder="请输入登录手机号"
          onChange={(e) => setTel(e.target.value)}
        />
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r border-bottom ai-center">
        <div className="minw-100">验证码(必填)</div>
        <input
          className="flex-1"
          value={captcha}
          placeholder="请输入验证码"
          onChange={(e) => setCaptcha(e.target.value)}
        />
        <Button className="minw-80" type="primary" size="sm" disabled={timing} onClick={handleGetCaptcha}>
          {timing ? `${captchaTime}s` : '获取验证码'}
        </Button>
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r border-bottom ai-center">
        <div className="minw-100">新密码(必填)</div>
        <input
          className="flex-1"
          value={password}
          type={passwordShow ? 'text' : 'password'}
          placeholder="请输入6位数字密码"
          onChange={(e) => setPassword(e.target.value)}
        />
        <img
          className="icon-20"
          src={`/pic/icon_eye${passwordShow ? '' : '_close'}.png`}
          alt=""
          onClick={() => setPasswordShow(!passwordShow)}
        />
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 d-flex-r border-bottom ai-center">
        <div className="minw-100">确认密码(必填)</div>
        <input
          className="flex-1"
          value={twoPassword}
          type={twoPasswordShow ? 'text' : 'password'}
          placeholder="请再次输入6位数字密码"
          onChange={(e) => setTwoPassword(e.target.value)}
        />
        <img
          className="icon-20"
          src={`/pic/icon_eye${twoPasswordShow ? '' : '_close'}.png`}
          alt=""
          onClick={() => setTwoPasswordShow(!twoPasswordShow)}
        />
      </div>
      <div className="mt-40 pl-20 pr-20">
        <Button type="primary" size="md" long onClick={handleUpdate}>
          修改密码
        </Button>
      </div>
    </Page>
  );
}
