import React from 'react';
interface Props {
  tips?: string;
  isError?: boolean;
  isPage?: boolean;
}
export default function NoData({ tips, isError, isPage = true }: Props) {
  return (
    <div className="pt-30 pb-30 d-flex-c ai-center">
      <img
        className={isPage ? 'w-200' : 'w-100'}
        src={isError ? '/pic/pic_page_status_error.png' : '/pic/pic_page_status_nodata.png'}
        alt=""
      />
      <div className="mt-20 text-color-9 font-size-12">{tips || '暂无数据'}</div>
    </div>
  );
}
