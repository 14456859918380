import React, { useState } from 'react';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import { ComplaintStatus, ComplaintWorkerType } from '../../../utils/dict';
import RadioBox from '../../../components/radioBox';
import { LoadingMask, showToast } from '../../../utils/utils';
import { apiCreateComplaint, apiUploadPic } from '../../../api';
import { useNavigate } from 'react-router-dom';
import main from '../../../main';

const fileLength = 2;
export default function ComplaintPage() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [complaintText, setComplaintText] = useState('');
  const [types, setTypes] = useState<string[]>([]);
  const [workOrderName, setWorkOrderName] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [fileImgs, setFileImgs] = useState<{ src: string; key: string }[]>([]);

  function handleSetType(type: string, label: string) {
    if (types.includes(type)) {
      setTypes(types.filter((t) => t !== type));
    } else {
      setTypes([...types, type]);
    }
    if (workOrderName.includes(label)) {
      setWorkOrderName(workOrderName.filter((t) => t !== label));
    } else {
      setWorkOrderName([...workOrderName, label]);
    }
  }

  function handleRemoveImg(idx: number) {
    let t = [...files];
    t.splice(idx, 1);
    setFiles(t);
    let tt = [...fileImgs];
    tt.splice(idx, 1);
    setFileImgs(tt);
  }

  async function handleConfirm() {
    if (!name) {
      return showToast('请输入姓名');
    }
    if (!/^1[0-9]{10}$/.test(tel)) {
      return showToast('请输入正确的手机号');
    }

    if (!complaintText) {
      return showToast('请描述您的问题');
    }
    try {
      LoadingMask.show('正在提交...');
      let _pics = [];
      if (files.length > 0) {
        for (let p of files) {
          let url = await apiUploadPic(p);
          _pics.push(url);
        }
      }
      let body = {
        workOrderName: workOrderName ? workOrderName.join(',') : '',
        workOrderType: types ? types.join(',') : '',
        workOrderImg: _pics.length > 0 ? _pics.join(';') : '',
        workOrderContent: complaintText,
        name: name,
        phoneNumber: tel,
        emNumber: main.currentEm?.emNumber,
        emId: main.currentEm?.id,
        status: ComplaintStatus.wait,
      };
      var res = await apiCreateComplaint(body);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '提交成功');
        navigate(-1);
      } else {
        showToast(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message || '提交失败');
    } finally {
      LoadingMask.hide();
    }
  }
  const handleImageUpload = (event: any) => {
    let filesList = event.target.files;
    let _files: File[] = [...files];
    if (filesList && filesList[0]) {
      _files.push(filesList[0]);
    }
    if (_files.length > fileLength) {
      _files = _files.slice(0, fileLength);
    }
    setFiles(_files);
    for (let i = 0; i < filesList.length; i++) {
      const file = filesList[i];
      // 创建FileReader实例
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // 使用文件名或索引来保证顺序
        setFileImgs((prevImages) => [...prevImages, { src: e.target.result, key: file.name || i }]);
      };

      reader.readAsDataURL(file);
    }
  };

  function buildType(t: string, label: string) {
    return (
      <div
        className="d-flex-r"
        onClick={() => {
          handleSetType(t, label);
        }}
      >
        <RadioBox className="mr-5" value={types.includes(t)} checkedImg="complaint" />
        <div className="font-size-12">{label}</div>
      </div>
    );
  }
  return (
    <Page
      className="d-flex-c bg-white complaint-page"
      contentClassName="flex-1 overflow-y h-1 p-20"
      appBar={<AppBar title="投诉" />}
    >
      <div className="font-size-18 font-widget-600 mb-20">请填写以下信息投诉商家</div>
      <div className="mb-20 text-color-9">
        非常抱歉给您带来不便，我们重视每一位用户的使用体验，请填写信息帮助我们明确问题。
      </div>
      <div className="mb-15">
        <span className="font-widget-600 mr-5">投诉商家原因</span>
        <span className="text-color-9">(多选)</span>
      </div>
      <div className="d-flex-r jc-between ">
        {buildType(ComplaintWorkerType.active, ComplaintWorkerType.getTypeStr('active'))}
        {buildType(ComplaintWorkerType.real, ComplaintWorkerType.getTypeStr('real'))}
        {buildType(ComplaintWorkerType.packOrder, ComplaintWorkerType.getTypeStr('packOrder'))}
      </div>
      <div className="d-flex-r jc-between mt-10">
        {buildType(ComplaintWorkerType.pay, ComplaintWorkerType.getTypeStr('pay'))}
        {buildType(ComplaintWorkerType.speed, ComplaintWorkerType.getTypeStr('speed'))}
        {buildType(ComplaintWorkerType.service, ComplaintWorkerType.getTypeStr('service'))}
      </div>
      <div className="mt-15 mb-15">
        <span className="font-widget-600 mr-5">投诉原因</span>
        <span className="text-color-9">(必填)</span>
      </div>
      <div className="pos-r">
        <textarea
          className="border-none bw-100 h-70 textarea font-size-12"
          maxLength={100}
          placeholder="请填写10字以上100字以内投诉原因以便我们核实情况"
          onChange={(e) => setComplaintText(e.currentTarget.value)}
        />
        <span className="pos-a text-color-9 b-10 r-0">{complaintText.length}/100</span>
      </div>
      <div className="mt-15 mb-15">
        <span className="font-widget-600 mr-5">图片证据</span>
        <span className="text-color-9">(选填)</span>
      </div>
      <div className="text-color-9 font-size-12">
        请上传与本次投诉相关的图片证据，最多上传两张证据，您所上传的图片商家可见，请注意保护个人隐私!
      </div>
      <div className="mt-10 pb-20 border-bottom d-flex-r pos-r">
        <div className={`w-60 h-60 pos-r overflow-hidden ${files.length >= fileLength ? 'd-none' : ''}`}>
          <img className="w-58 h-58 d-flex-r jc-center" src="/pic/icon_upload.png" alt="" />
          <input
            className="view-opacity-0 pos-a l-0 t-0 bw-100 line-height-60"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
        {fileImgs.map((item, idx) => {
          return (
            <div key={item.key} className="w-60 h-60 border pos-r mr-10">
              <img className="w-58 h-58" src={item.src} alt="" />
              <img
                className="pos-a w-16 h-16 t-0 r-0"
                onClick={() => handleRemoveImg(idx)}
                src="/pic/icon_upload_close.png"
                alt=""
              />
            </div>
          );
        })}
        <span className="pos-a text-color-9 b-20 r-0">{fileImgs.length}/2</span>
      </div>
      <div className="mt-15 pb-15 border-bottom">
        <span className="font-widget-600 mr-5">联系方式</span>
        <span className="text-color-9">(必填)</span>
      </div>
      <div className="mt-10 mb-10 d-flex-r font-size-12">
        <div className="w-70">联系人姓名</div>
        <input
          className="flex-1 font-size-12 input-style pb-10"
          type="text"
          placeholder="请输入姓名"
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </div>
      <div className="mt-10 mb-10 d-flex-r font-size-12">
        <div className="w-70">联系电话</div>
        <input
          className="flex-1 font-size-12 input-style pb-10"
          type="text"
          placeholder="请输入联系电话"
          maxLength={11}
          onChange={(e) => setTel(e.currentTarget.value)}
        />
      </div>
      <div className="mt-20 text-color-9 font-size-12">
        点击以下按钮,即代表您同意商家客服获取投诉描述、图片证据和手机号,以便联系您解决问题
      </div>
      <div
        className="mt-20 pt-10 pb-10 border-radius-8 bg-green text-color-white text-center"
        onClick={() => handleConfirm()}
      >
        提交
      </div>
    </Page>
  );
}
