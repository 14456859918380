import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import AppBar from '../../components/appBar';
import TabBar from '../../components/tabBar';
import Button from '../../components/button';
import { useNavigate } from 'react-router-dom';
import { LoadingMask, formatNumber, showToast } from '../../utils/utils';
import { apiGetEmPackList } from '../../api';
import main from '../../main';
import dayjs from 'dayjs';
import NoData from '../../components/noData';
import { PackageType } from '../../utils/dict';

const tabOptions = ['本期可订购套餐', '下期可续费套餐'];
export default function SelectPackPage() {
  const navigate = useNavigate();
  const [list, setList] = useState<EmPack[]>([]);
  const [tabValue, setTabValue] = React.useState(tabOptions[0]);
  // 分类列表
  const [catList, setCatList] = useState<CardPackType[]>([]);
  // 已选分类
  const [cat, setCat] = useState('');

  useEffect(() => {
    loadPackList();
  }, []); // eslint-disable-line

  useEffect(() => {
    handleResetList();
  }, [list, tabValue]); // eslint-disable-line

  async function loadPackList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetEmPackList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _list: EmPack[] = data.data ?? [];
        setList(_list);
      } else {
        showToast(data.msg ?? '获取套餐列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取套餐列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  /**
   * 重置列表 计算能显示的列表再统计出分类 主要是为了算分类
   */
  function handleResetList() {
    let _list = list;
    if (tabValue === tabOptions[0]) {
      // 买本月
      _list = list.filter((item) => {
        if (main.currentEm?.packDetailId && main.currentEm?.isExperience === 'Y') {
          // 本月是体验包 只能买非体验包的基础包
          return item.packageType === PackageType.basicpackage && item.isExperience !== 'Y';
        } else if (!main.currentEm?.packDetailId) {
          // 本月是空 可以买所有基础包 包括体验包
          return item.packageType === PackageType.basicpackage;
        } else if (!!main.currentEm?.packDetailId) {
          // 本月是基础包 只能买加油包
          return item.packageType === PackageType.fuelpack;
        }
        return false;
      });
    } else if (tabValue === tabOptions[1]) {
      _list = _list.filter((i) => i.packageType === PackageType.basicpackage && i.isExperience !== 'Y');
    }
    let _catList: CardPackType[] = [];
    let _catMap: { [x: string]: CardPackType } = {};
    for (let item of _list) {
      _catMap[item.cardPackTypeId ?? 'test'] = item.cardPackType ?? { id: 'test', name: '其他分类', sort: 99999 };
    }

    _catList = Object.values(_catMap);
    if (_catList.length <= 1) {
      _catList = [];
    }
    _catList.sort((a, b) => a.sort - b.sort);
    setCatList(_catList);
    if (_catList.length) {
      setCat(_catList[0].id);
    } else if (!_catList.length) {
      setCat('');
    }
  }

  function buildListItem(i: EmPack, idx: number) {
    return (
      <div
        key={(i.id ?? '') + idx}
        className="mt-10 bg-white pt-26 pb-10 pl-14 pr-14 border border-radius-5 border-color-gray d-flex-r ai-center pos-r"
      >
        <div
          className={`pt-2 pb-2 pl-8 pr-8 ${
            i.packageType === PackageType.basicpackage
              ? i.isExperience === 'Y'
                ? 'bg-green'
                : 'bg-transparent'
              : 'bg-origin'
          } text-color-white font-size-12 border-radius-tl-5 border-radius-br-5 pos-a l-0 t-0`}
        >
          {i.packageType === PackageType.basicpackage ? (i.isExperience === 'Y' ? '体验包' : '') : '加油包'}
        </div>
        <div className="flex-1">
          <div className="d-flex-r">
            <div className="mr-10">{i.name}</div>
          </div>
          <div className="font-size-12 text-color-9 mt-5">
            有效期
            {dayjs(tabValue === tabOptions[0] ? i.startEffectTime : i.nextStartEffectTime).format('YYYY-MM-DD')}至
            {dayjs(tabValue === tabOptions[0] ? i.endEffectTime : i.nextEndEffectTime).format('YYYY-MM-DD')}
          </div>
        </div>
        <div className="text-center">
          <div className="font-size-18 font-widget-600 text-color-red">
            ￥{formatNumber(i.discountPrice || i.salePrice)}
          </div>
          {!!i.discountPrice && (
            <div className="font-size-12 text-color-9 text-deleteline">￥{formatNumber(i.salePrice)}</div>
          )}
        </div>
        <Button
          className="ml-10"
          type="origin-red"
          size="xs"
          onClick={() =>
            navigate('/pack-order-confirm', {
              state: { emPack: i, dateType: tabValue === tabOptions[0] ? 'now' : 'next' },
            })
          }
        >
          订购
        </Button>
      </div>
    );
  }

  function buildGridItem(i: EmPack, idx: number) {
    let priceStr: any = '';
    if (i.priceType === 'month') {
      priceStr = (
        <div>
          <span className="font-size-20">{formatNumber((i.salePrice ?? 0) / (i.monthNum ?? 1))}</span>元/月
        </div>
      );
    } else if (i.priceType === 'day') {
      let diff = dayjs(i.endEffectTime).diff(dayjs(i.startEffectTime), 'day');
      priceStr = (
        <div>
          <span className="font-size-20">{formatNumber((i.salePrice ?? 0) / (diff ?? 1))}</span>元/天
        </div>
      );
    } else {
      priceStr = (
        <div>
          <span className="font-size-20">{formatNumber(i.salePrice)}</span>元
        </div>
      );
    }
    return (
      <div
        onClick={() =>
          navigate('/pack-order-confirm', {
            state: { emPack: i, dateType: tabValue === tabOptions[0] ? 'now' : 'next' },
          })
        }
        key={(i.id ?? '') + idx}
        className="w-157 bg-white mr-20 mb-20 pt-20 pb-20 pl-10 pr-10 border border-radius-5 border-color-gray pos-r d-flex-c ai-center pos-r"
      >
        <div
          className={`pt-2 pb-2 pl-8 pr-8 ${
            i.packageType === PackageType.basicpackage
              ? i.isExperience === 'Y'
                ? 'bg-green'
                : 'bg-transparent'
              : 'bg-origin'
          } text-color-white font-size-12 border-radius-tl-5 border-radius-br-5 pos-a l-0 t-0 z-10`}
        >
          {i.packageType === PackageType.basicpackage ? (i.isExperience === 'Y' ? '体验包' : '') : '加油包'}
        </div>
        <div className="font-size-14 font-widget-600">{i.name}</div>
        <div className="text-color-red mt-10">{priceStr}</div>
        <div className="font-size-12 text-color-6 mt-10 pl-5 pr-5">{i.remark ?? ''}</div>
        {!!i.cardPackTag && (
          <div className="pos-a l-0 z-10 h-20 w-75" style={{ top: '-10px' }}>
            <img className="pos-a l-0 t-0 w-75 h-20" src={i.cardPackTag.imgUrl} alt="" />
            <div className="pos-a l-5 t-0 b-0 word-nowrap line-height-20 font-size-10 text-color-white">
              {i.cardPackTag!.name ?? ''}
            </div>
          </div>
        )}
      </div>
    );
  }

  let _list = list;
  if (tabValue === tabOptions[0]) {
    // 买本月
    _list = list.filter((item) => {
      if (main.currentEm?.packDetailId && main.currentEm?.isExperience === 'Y') {
        // 本月是体验包 只能买非体验包的基础包
        return item.packageType === PackageType.basicpackage && item.isExperience !== 'Y';
      } else if (!main.currentEm?.packDetailId) {
        // 本月是空 可以买所有基础包 包括体验包
        return item.packageType === PackageType.basicpackage;
      } else if (!!main.currentEm?.packDetailId) {
        // 本月是基础包 只能买加油包
        return item.packageType === PackageType.fuelpack;
      }
      return false;
    });
  } else if (tabValue === tabOptions[1]) {
    // 买下期 只能买 非体验包的基础包
    _list = list.filter((item) => item.packageType === PackageType.basicpackage && item.isExperience !== 'Y');
  }
  if (!!cat) {
    _list = _list.filter((item) => {
      let a = cat === 'test' ? !item.cardPackTypeId : item.cardPackTypeId === cat;
      return a;
    });
  }

  return (
    <Page className="d-flex-c bg-gray" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="订购套餐" />}>
      <TabBar
        type="origin-red"
        className="border-bottom"
        options={tabOptions}
        value={tabValue}
        onChange={(v) => setTabValue(v)}
      />
      {!!catList.length && (
        <div className="pl-20 pr-20 bg-white">
          <div className="pt-10 pb-10 overflow-x d-flex-r word-nowrap">
            {catList.map((i, idx) => (
              <div
                key={i.id + idx}
                className={`pt-5 pb-5 pl-10 pr-10 mr-10 border-radius-40 border border-color-origin-red ${
                  i.id === cat ? 'bg-origin-red text-color-white' : 'bg-origin-red-t text-color-origin-red'
                }`}
                onClick={() => setCat(i.id)}
              >
                {i.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {main.currentEm?.cardProductVo?.listType === 'list' ? (
        <div className="overflow-y ml-20 mr-20">
          {!_list.length && <NoData tips="暂无可用套餐" />}
          {_list.map((i, idx) => buildListItem(i, idx))}
        </div>
      ) : (
        <div className="overflow-y ml-20 pt-20">
          {!_list.length && <NoData tips="暂无可用套餐" />}
          <div className="d-flex-r flex-wrap">{_list.map((i, idx) => buildGridItem(i, idx))}</div>
        </div>
      )}
    </Page>
  );
}
