import { useNavigate } from 'react-router-dom';
import AppBar from '../../components/appBar';
import Page from '../../components/page';
import Button from '../../components/button';
import { useEffect, useState } from 'react';
import { apiGetIncomeList } from '../../api';
import main from '../../main';
import NoData from '../../components/noData';
import SelectDialog from '../../components/selectDialog';
import dayjs from 'dayjs';

const incomeOptions = ['全部', '充值记录', '消费记录', '退订记录'];
const incomeValueOptions = ['', 'recharge', 'buyPackOrder', 'returnPackOrder'];
export default function IncomeListPage() {
  const navigate = useNavigate();

  const [list, setList] = useState<Income[]>([]);
  const [isError, setIsError] = useState(false);
  const [timeSelect, setTimeSelect] = useState(incomeOptions[0]);
  const [timeSelectDialogShow, setTimeSelectDialogShow] = useState(false);
  const [incomeSelect, setIncomeSelect] = useState(incomeOptions[0]);
  const [incomSelectDialogShow, setIncomSelectDialogShow] = useState(false);
  useEffect(() => {
    loadList();
  }, []);

  async function loadList() {
    try {
      let res = await apiGetIncomeList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
        setIsError(false);
      }
    } catch (e: any) {
      setIsError(true);
      console.error(e);
    }
  }

  let timeList: string[] = [];
  for (let i of list) {
    let time = dayjs(i.createTime).format('YYYY-MM');
    if (!timeList.includes(time)) {
      timeList.push(time);
    }
  }
  timeList.unshift(incomeOptions[0]);

  let _list = list;
  if (timeSelect !== incomeOptions[0]) {
    _list = list.filter((i) => dayjs(i.createTime).format('YYYY-MM') === timeSelect);
  }
  if (incomeSelect !== incomeOptions[0]) {
    _list = _list.filter((i) => i.businessType === incomeValueOptions[incomeOptions.indexOf(incomeSelect)]);
  }

  return (
    <Page className="d-flex-c bg-gray" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="充消记录" />}>
      <div className="mt-10 mb-10 p-10 pl-20 pr-20 bg-white d-flex-r ai-center">
        <div className="flex-1 font-widget-600 font-size-16">
          余额:<span className="ml-5">{main.currentEm?.cardDetailWalletVo?.availableAmount ?? 0}元</span>
        </div>
        <Button type="primary" size="sm" onClick={() => navigate('/recharge')}>
          充值
        </Button>
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 bg-white border-bottom d-flex-r">
        {/* 时间下拉框 */}
        <div className="flex-1 pos-r d-flex-r ai-center" onClick={() => setTimeSelectDialogShow(!timeSelectDialogShow)}>
          <div className="flex-1 text-center">{timeSelect}</div>
          <img className="icon-24" src="/pic/icon_select_down.png" alt="" />
          {timeSelectDialogShow && (
            <SelectDialog activeItem={timeSelect} list={timeList} onCancel={(v) => setTimeSelect(v)} />
          )}
        </div>
        {/* 冲消记录下拉框 */}
        <div
          className="flex-1 pos-r d-flex-r ai-center"
          onClick={() => setIncomSelectDialogShow(!incomSelectDialogShow)}
        >
          <div className="flex-1 text-center">{incomeSelect}</div>
          <img className="icon-24" src="/pic/icon_select_down.png" alt="" />
          {incomSelectDialogShow && (
            <SelectDialog activeItem={incomeSelect} list={incomeOptions} onCancel={(v) => setIncomeSelect(v)} />
          )}
        </div>
      </div>
      <div className="flex-1 h-1 overflow-y bg-white">
        {!_list.length && <NoData isError={isError} tips="暂无冲消记录" />}

        {_list.map((i, idx) => (
          <div key={idx} className="pt-10 pb-10 pl-20 pr-20 border-bottom d-flex-r ai-center">
            <div className="flex-1">
              <div>{i.businessTip}</div>
              <div className="font-size-12 text-color-9 mt-5">{i.createTime}</div>
            </div>
            <div className="font-size-18 font-widget-600">￥{i.availableAmountOperation}</div>
          </div>
        ))}
      </div>
    </Page>
  );
}
