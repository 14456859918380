import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import Page from '../../components/page';
import RadioBox from '../../components/radioBox';
import { LoadingMask, showConfirm, showToast } from '../../utils/utils';
import { apiGetCommonCaptcha, apiGetEmCount, apiGetUserInfo, apiLogin } from '../../api';
import main, { AppLocalKey } from '../../main';
import { ProtocolType } from '../../utils/dict';

export default function LoginPage() {
  const navigate = useNavigate();
  const naviate = useNavigate();
  const [phone, setPhone] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [checkProtocol, setCheckProtocol] = useState(false);
  const timeRef = useRef<{ time: number; timer?: NodeJS.Timer }>({ time: 60, timer: undefined });
  const [captchaTime, setCaptchaTime] = useState(60);
  const [timing, setTiming] = useState(false);

  useEffect(() => {
    let _phone = localStorage.getItem(AppLocalKey.loginUsername);
    if (_phone) {
      setPhone(_phone);
    }
  }, []);

  function startTimer() {
    let jsTimer: NodeJS.Timer = setInterval(() => {
      let t = timeRef.current.time - 1;
      if (t <= 0) {
        setTiming(false);
        clearInterval(timeRef.current.timer);
        timeRef.current.timer = undefined;
      }
      timeRef.current.time = t;
      setCaptchaTime(t);
    }, 1000);
    timeRef.current.timer = jsTimer;
  }

  async function handleGetCaptcha() {
    if (timeRef.current.timer) return;
    if (!/^1[0-9]{10}$/.test(phone)) {
      return showToast('请输入正确的手机号');
    }

    LoadingMask.show('正在发送...');
    try {
      let res = await apiGetCommonCaptcha(phone);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '验证码已发送');
        setTiming(true);
        setCaptchaTime(60);
        timeRef.current.time = 60;
        startTimer();
      } else {
        showToast(data.msg || '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '验证码发送失败,请稍后再试');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleLogin() {
    let exp = /^1[0-9]{10}$/;
    if (!exp.test(phone)) {
      return showToast('手机号格式不正确');
    } else if (!captcha) {
      return showToast('请输入验证码');
    } else if (!checkProtocol) {
      let modalRes = await showConfirm({
        title: '温馨提示',
        content: '点击同意表示您已阅读并同意《用户协议》和《隐私政策》',
        confirmBtnText: '同意',
      });
      if (!modalRes) return;
    }
    localStorage.setItem(AppLocalKey.loginUsername, phone);
    LoadingMask.show('正在登录...');
    try {
      let res = await apiLogin(phone, captcha);
      let data = res.data;
      if (data.code === 200) {
        let token = data.data.access_token;
        main.loginKey = token;
        localStorage.setItem(AppLocalKey.loginToken, token);

        let userInfoRes = await apiGetUserInfo(token);
        let userInfoData = userInfoRes.data;
        if (userInfoData.code === 200) {
          main.user = userInfoData.data.user;
          showToast('登录成功');
          let res = await apiGetEmCount();
          let data = res.data;
          if (data.data <= 0) {
            naviate('/em-list', { state: { backToOut: true } });
          } else {
            naviate('/home', { replace: true });
          }
        }
      } else {
        showToast(data.msg ?? '登录失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '登录失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page className="d-flex-c login-page" contentClassName="flex-1 d-flex-c h-1 pos-r">
      <img className="bw-100 pos-a" src="/pic/pic_login_bg.png" alt="" />
      <div className="pos-a l-0 r-0 t-0 b-0 pl-30 pr-30 pt-30 d-flex-c overflow-y">
        <img className="icon-40 border-radius-8" src="/pic/logo.png" alt="" />
        <div className="font-size-30 mt-5">你好</div>
        <div className="font-size-20 mt-5">欢迎使用「网司令」</div>
        <div className="mt-40">手机号</div>
        <div className="border h-36 mt-10 bg-gray d-flex-r border-radius-60 ai-center">
          <input
            className="flex-1 ml-10 mr-10 h-36"
            placeholder="请输入手机号"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {!!phone && (
            <img className="icon-14 mr-10" src="/pic/icon_close_black.png" alt="" onClick={() => setPhone('')} />
          )}
        </div>
        <div className="mt-30">验证码</div>
        <div className=" d-flex-r h-36 mt-10 bg-gray border-radius-60 ai-center">
          <input
            className="flex-1 ml-10 mr-10 h-36"
            placeholder="请输入验证码"
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
          />
          <div
            className={`font-size-12 mr-10 ${timing ? 'text-color-p-t' : 'text-color-p'}`}
            onClick={timing ? undefined : handleGetCaptcha}
          >
            {timing ? `${captchaTime}s` : '获取验证码'}
          </div>
        </div>
        <div className="flex-1 mt-40"></div>
        <div className="bw-100">
          <Button long type="primary" size="lg" shape="circle" onClick={handleLogin}>
            登录/注册
          </Button>
        </div>
        <div className="d-flex-r ai-center jc-center bw-100 mt-20">
          <RadioBox className="mr-10" value={checkProtocol} onChange={() => setCheckProtocol(!checkProtocol)} />
          <div className="font-size-12">
            <span onClick={() => setCheckProtocol(!checkProtocol)}>已阅读并同意</span>
            <span className="text-color-p" onClick={() => navigate('/protocol', { state: ProtocolType.userAgreement })}>
              《用户协议》
            </span>
            和
            <span
              className="text-color-p"
              onClick={() => navigate('/protocol', { state: ProtocolType.privacyAgreement })}
            >
              《隐私政策》
            </span>
          </div>
        </div>
        <div className="flex-1 mt-40"></div>
        <div className="mb-20 bw-100 text-center text-color-9">
          <span onClick={() => naviate('/customer')}>联系客服</span>
          <span className="ml-10 mr-10">|</span>
          <span onClick={() => window.open(`tel:${main.tel400}`)}>服务热线</span>
        </div>
      </div>
    </Page>
  );
}
