import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiCreateFirstActivityOrder,
  apiGetFirstActivityList,
  apiGetPayConfigReduction,
  apiGetPayTypeConfigList,
  apiPayFirstActivityOrder,
} from '../../api';
import AppBar from '../../components/appBar';
import NoData from '../../components/noData';
import Page from '../../components/page';
import main from '../../main';
import { PayType } from '../../utils/dict';
import showPayPage from '../../utils/pay';
import { LoadingMask, modelParsePrice, showBottom, showToast } from '../../utils/utils';
import ActivityPaySheet from './activityPaySheet';

export default function ActivityPage() {
  const navigate = useNavigate();
  const [activity, setActivity] = useState<ChargeFirst>();

  const [reduction, setReduction] = useState<any>({});
  const [payConfigList, setPayConfigList] = useState<PayTypeConfig[]>([]);
  const [payType, setPayType] = useState<string>();
  const [wxReady, setWxReady] = useState(false);
  useEffect(() => {
    loadActivity();
    loadPayConfigReduction();
    loadPayConfigList();
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener(
          'WeixinJSBridgeReady',
          () => {
            setWxReady(true);
          },
          false
        );
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => {
          setWxReady(true);
        });
      }
    } else {
      setWxReady(true);
    }
  }, []); // eslint-disable-line

  /**
   * 获取支付配置列表
   */
  async function loadPayConfigList() {
    try {
      let res = await apiGetPayTypeConfigList();
      let data = res.data;
      if (data.code === 200) {
        let list: PayTypeConfig[] = data.data ?? [];
        list = list.filter((i) => i.payType !== PayType.balance);
        setPayConfigList(list);
        let payTypes = list.map((i: PayTypeConfig) => i.payType!);
        if (payTypes.length && !payType) {
          setPayType(payTypes[0]);
        }
      }
    } catch (e) {}
  }

  async function loadPayConfigReduction() {
    try {
      let res = await apiGetPayConfigReduction(main.currentEm!.id!);
      let data = res.data;
      if (data.code === 200) {
        let _map: any = {};
        (data.data ?? []).forEach((i: any) => (_map[i.payType] = i));
        setReduction(_map);
      }
    } catch (e) {}
  }

  async function loadActivity() {
    if (!main.currentEm?.id) {
      navigate(-1);
      return;
    }
    LoadingMask.show('正在加载...');
    try {
      let res = await apiGetFirstActivityList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        let _list: ChargeFirstCoupon[] = [];
        if (_data.payDetails) {
          _data.payDetails.forEach((i: any) => {
            _list.push(Object.assign({}, i, { amount: modelParsePrice(i.amount), price: modelParsePrice(i.price) }));
          });
        }
        setActivity(Object.assign({}, _data, { payDetails: _list }));
      } else {
        showToast(data.msg ?? '获取活动失败,请重试');
        navigate(-1);
      }
    } catch (e: any) {
      showToast(e.message ?? '获取活动失败,请重试');
      navigate(-1);
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleBuy(item: ChargeFirstCoupon) {
    if (!main.openId) {
      return showToast('授权失败,请在微信内打开');
    }
    let _payType = await showBottom((onOk) => (
      <ActivityPaySheet payType={payType} payTypeList={payConfigList} reduction={reduction} onOk={onOk} />
    ));
    if (!_payType) return;
    try {
      LoadingMask.show('正在参与...');
      let res = await apiCreateFirstActivityOrder(main.currentEm!.id!, item.payDetailId!);
      let data = res.data;
      if (data.code === 200) {
        let orderId = data.data.orderId;
        if (orderId) {
          let payRes = await apiPayFirstActivityOrder(orderId, _payType, main.openId!, main.appId!);
          let payData = payRes.data;
          if (payData.code === 200) {
            let _data = payData.data;
            if (wxReady) {
              let payRes = await showPayPage(_data, _payType);
              if (payRes.code === 200) {
                showToast('参与成功');
                navigate(-1);
              } else if (payRes.code === 300) {
                if (payRes.url) {
                  LoadingMask.hide();
                  navigate('/iframe', { state: { url: payRes.url, title: '银联支付', pay: true, back: true } });
                }
              } else if (payRes.code === 500) {
                showToast(payRes.msg);
              }
            } else {
              showToast('数据还在准备,请稍后重试');
            }
          } else {
            showToast(data.msg ?? '参与失败');
          }
        }
      } else {
        showToast(data.msg ?? '参与失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '参与失败');
    } finally {
      LoadingMask.hide();
    }
  }

  if (!activity) {
    return (
      <Page appBar={<AppBar title="首充活动" />}>
        <NoData isPage tips="正在加载..." />
      </Page>
    );
  }

  return (
    <div className="pos-r bg-white">
      <img className="w-375 pos-a z-0 l-0 t-0" src={activity?.activeImg} alt="" />
      <Page
        className="d-flex-c pos-r z-10 activity-page"
        contentClassName="flex-1 h-1 d-flex-c overflow-hidden"
        appBar={
          <AppBar
            white
            title="首充活动"
            className="bg-transparent text-color-white"
            showBack={activity?.forceActivity !== 'Y'}
          />
        }
      >
        <div className="mt-240 flex-1 h-1 overflow-y">
          <div className="d-shadow m-20 p-10 bg-white border-radius-8">
            <div className="d-flex-r ai-center">
              <div className="title-dot"></div>
              <div className="font-widget-600 font-size-16">官方正品/套餐优惠/随用随充</div>
            </div>
            {(activity?.payDetails ?? []).map((i, index) => (
              <div className="coupon-item" key={index} onClick={() => handleBuy(i)}>
                <div className="item-left">
                  <div className="item-top-num">已有2235人参与</div>
                  <div className="item-price">
                    充<p className="item-price-num">{i.amount}</p>得
                    <p className="item-price-num font-size">{i.price}</p>元
                  </div>
                </div>
                <div className="item-right">
                  <p className="item-right-btn">立即参加活动</p>
                </div>
              </div>
            ))}
          </div>
          <div className="font-size-12 text-color-9 m-20 word-wrap">{activity.activeDesc}</div>
          <div className="bg-white d-shadow border-radius-8 m-20 p-10">
            <div className="d-flex-r ai-center">
              <div className="title-dot"></div>
              <div className="font-widget-600 font-size-16">活动详情</div>
            </div>
            <div
              className="font-size-12 line-height-16 mt-20 word-wrap"
              dangerouslySetInnerHTML={{ __html: (activity.activeDetail ?? '').replaceAll('\n', '<br/>') }}
            ></div>
          </div>
        </div>
      </Page>
    </div>
  );
}
