import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const itemWidth = 56;
const itemHeight = 65;
const space = 20;
export default function FirstActiveFloat() {
  const navigate = useNavigate();
  const divRef = useRef<any>();
  const [left, setLeft] = useState<number>(window.innerWidth - itemWidth - space); // 向左移动的距离
  const [top, setTop] = useState<number>(window.innerHeight - itemHeight - 400); // 向上移动的距离

  //开始拖拽
  function handleDragStart() {
    divRef!.current!.style.transition = 'none';
    document.body.style.overflow = 'hidden';
  }
  //触摸移动
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (e.targetTouches.length === 1) {
      let touch = e.targetTouches[0];
      let left = touch.clientX - itemWidth / 2;
      let top = touch.clientY - itemHeight / 2;
      if (left < space) {
        left = space;
      }
      if (left > window.innerWidth - itemWidth - space) {
        left = window.innerWidth - itemWidth - space;
      }
      if (top < space) {
        top = space;
      }
      if (top > window.innerHeight - itemHeight - space) {
        top = window.innerHeight - itemHeight - space;
      }
      setLeft(left);
      setTop(top);
    }
  }
  function hanldeTouchEnd() {
    document.body.style.overflow = '';
  }
  return (
    <img
      className="first-active-float route-animation"
      src="/pic/icon_activity_enter.png"
      alt=""
      ref={divRef}
      style={{
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
        left: `${left}px`,
        top: `${top}px`,
      }}
      onTouchStart={(e) => handleDragStart()}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => hanldeTouchEnd()}
      onClick={() => navigate('/activity')}
    />
  );
}
