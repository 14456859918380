import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import AppBar from '../../components/appBar';
import TabBar from '../../components/tabBar';
import Button from '../../components/button';
import NoData from '../../components/noData';
import { apiGetCouponList, apiGetCouponTypeCount, apiUseFlowCoupon } from '../../api';
import main from '../../main';
import { LoadingMask, flowKTOG, showConfirm, showToast } from '../../utils/utils';
import { CouponStatus, CouponType } from '../../utils/dict';
import dayjs from 'dayjs';

const tabOptions = ['全部', '流量券', '抵扣券'];
const tabOptionValues = ['all', 'flow', 'payment'];

const statusOptions = ['全部', '未使用', '已使用', '已过期'];
const statusOptionValues = ['all', 'N', 'Y', 'expired'];

export default function CouponListPage() {
  const [typeValue, setTypeValue] = useState(0);
  const [status, setStatus] = useState(statusOptionValues[0]);
  const [list, setList] = useState<Array<Coupon>>([]);
  const [typeCount, setTypeCount] = useState<number[]>([]);

  useEffect(() => {
    loadList();
    loadCount();
  }, []);

  async function loadCount() {
    try {
      let res = await apiGetCouponTypeCount(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        setTypeCount([data.data.allCount, data.data.flowCount, data.data.paymentCount]);
      }
    } catch (e) {}
  }

  async function loadList() {
    try {
      let res = await apiGetCouponList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
      } else {
        showToast(data.msg ?? '获取卡券失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取卡券失败');
    }
  }

  let _list = list;

  if (tabOptionValues[0] !== tabOptionValues[typeValue]) {
    _list = _list.filter((i) => i.couponType === tabOptionValues[typeValue]);
  }
  if (statusOptionValues[0] !== status) {
    _list = _list.filter((i) => i.status === status);
  }
  let statusN = _list.filter((i) => i.status === 'N');
  let statusY = _list.filter((i) => i.status === 'Y');
  let statusExpired = _list.filter((i) => i.status === 'expired');
  _list = [...statusN, ...statusY, ...statusExpired];

  async function handleUseCoupon(item: Coupon) {
    if (main.currentEm?.isExperience === 'Y') {
      return showToast('当前使用的是体验包，无法使用流量券增加流量！');
    }
    let modalRes = await showConfirm({
      title: '用券说明',
      content: (
        <div className="d-flex-c align-items-start p-1-4 text-left font-size-12">
          <div>优惠券类型：流量包券</div>
          <div>预加流量：{flowKTOG(item.addFlow)}G</div>
          <div>生效方式：立即生效</div>
          <div>备注：流量包立即生效，叠加本月基础包流量，有效期与本期套餐包一致，流量不可跨月。</div>
        </div>
      ),
    });
    if (modalRes !== 1) return;
    try {
      LoadingMask.show('正在使用...');
      var res = await apiUseFlowCoupon(item.id!);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '使用成功');
        loadList();
        loadCount();
      } else {
        showToast(data.msg ?? '使用失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '使用失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page
      className="d-flex-c bg-gray coupon-list-page"
      contentClassName="flex-1 d-flex-c h-1"
      appBar={<AppBar title="卡券中心" />}
    >
      <TabBar
        className="mt-10 border-bottom"
        options={tabOptions}
        optionExpands={typeCount.map((i) => `(${i})`)}
        value={tabOptions[typeValue]}
        onChange={(v) => setTypeValue(tabOptions.indexOf(v))}
      />
      <div className="d-flex-r p-10 pl-20 pr-20 bg-white border-bottom">
        {statusOptionValues.map((i, idx) => (
          <Button
            key={i + idx}
            className="mr-20"
            size="xs"
            outline={status !== i}
            type={status === i ? 'primary' : 'default'}
            onClick={() => setStatus(i)}
          >
            {statusOptions[idx]}
          </Button>
        ))}
      </div>
      <div className="flex-1 h-1 overflow-y ml-10 mr-10">
        {!_list.length && <NoData tips="暂无卡券" />}
        {_list.map((i) => (
          <div key={i.id} className="mt-10 bg-white border-radius-8 d-flex-r ai-center">
            <div
              className={`w-70 h-70 border-radius-4 m-10 bg-${
                i.couponType === CouponType.flow ? 'primary' : 'origin'
              }-t1 d-flex-c ai-center jc-center`}
            >
              <img
                className="w-40 h-40"
                src={`/pic/icon_coupon_${i.couponType}_${i.status === CouponStatus.expired ? 'expired' : 'active'}.png`}
                alt=""
              />
              <div
                className={`font-size-12 ${
                  i.status === CouponStatus.expired
                    ? 'text-color-9'
                    : i.couponType === CouponType.flow
                    ? 'text-color-p'
                    : 'text-color-origin'
                }`}
              >
                {CouponType.getTypeStr(i.couponType)}
              </div>
            </div>
            <div className="flex-1 w-1">
              <div className="font-size-14 font-widget-600 omit-text">{i.couponName}</div>
              <div className="font-size-12 text-color-9 mt-5 mb-5">充值卡号:{i.emNumber}</div>
              <div className="font-size-10 text-color-9 text-short">
                {dayjs(i.validBeginTime).format('YYYY.MM.DD HH:mm')}-{dayjs(i.validEndTime).format('YYYY.MM.DD HH:mm')}
              </div>
            </div>
            <div className="d-flex-c h-90 ai-center">
              <div className="bg-gray w-10 h-5 border-radius-bl-8 border-radius-br-8"></div>
              <div className="flex-1 w-0 border-left-dashed"></div>
              <div className="bg-gray w-10 h-5 border-radius-tl-8 border-radius-tr-8"></div>
            </div>
            <div className="w-90 h-90 d-flex-c ai-center jc-center pos-r">
              {
                <div
                  className={`pos-a r-0 t-0 border-radius-tr-8 border-radius-bl-8 pt-2 pb-2 pl-5 pr-5 font-size-12 bg-${
                    i.status === CouponStatus.unuse
                      ? 'red-t2 text-color-red'
                      : i.status === CouponStatus.used
                      ? 'green-t2 text-color-green'
                      : 'gray text-color-9'
                  }`}
                >
                  {CouponStatus.getTypeStr(i.status)}
                </div>
              }
              {i.status === CouponStatus.unuse && i.couponType === CouponType.flow && (
                <Button type="primary" outline shape="circle" onClick={() => handleUseCoupon(i)}>
                  立即使用
                </Button>
              )}
              {i.status === CouponStatus.unuse && i.couponType === CouponType.payment && (
                <div className="font-size-12 text-color-9">购买套餐可用</div>
              )}
              {i.status === CouponStatus.used && (
                <div className="font-size-12 text-color-9 text-center">
                  <div>使用日期</div>
                  <div>{dayjs(i.usedTime).format('YYYY-MM-DD')}</div>
                </div>
              )}
              {i.status === CouponStatus.expired && (
                <div className="font-size-12 text-color-9 text-center">
                  <div>过期时间</div>
                  <div>{dayjs(i.validEndTime).format('YYYY-MM-DD')}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
}
