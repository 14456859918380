import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiGetProtocol } from '../../../api';
import AppBar from '../../../components/appBar';
import Page from '../../../components/page';
import { ProtocolType } from '../../../utils/dict';
import { LoadingMask, showToast } from '../../../utils/utils';

export default function ProtocolPage() {
  const { state: type } = useLocation();
  const [protocol, setProtocol] = useState<Protocol>({});
  useEffect(() => {
    loadProtocol();
  }, []); // eslint-disable-line

  async function loadProtocol() {
    LoadingMask.show('正在加载...');
    try {
      let res = await apiGetProtocol(type);
      let data = res.data;
      if (data.code === 200) {
        let _protocol = data.rows?.length ? data.rows[0] : {};
        setProtocol(_protocol);
      } else {
        showToast(data.msg ?? '获取失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取失败');
    } finally {
      LoadingMask.hide();
    }
  }

  const title = ProtocolType.getTypeStr(type);
  const content = protocol.textContent ?? '';
  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 d-flex-c" appBar={<AppBar title={title} />}>
      <div className="bg-gray h-10"></div>
      <div className="flex-1 overflow-y">
        <div className="word-wrap p-10" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </Page>
  );
}
