import React from 'react';
interface Props {
  number: number;
  min: number;
  max: number;
  className?: string;
  onChange: (number: number) => void;
}
export default function CalcCount({ number, min, max, className, onChange }: Props) {
  function handleChange(v: number) {
    let res = number + v;
    if (res < min) {
      res = min;
    }
    if (res > max) {
      res = max;
    }
    onChange(res);
  }
  return (
    <div className={`d-flex-r just-content-end ${className}`}>
      <div
        className={`w-30 border-left border-top border-bottom border-radius-tl-4 border-radius-bl-4 font-size-16 ${
          number <= min ? ' border-color-9 text-color-9' : 'border-color-3'
        } text-center`}
        onClick={() => handleChange(-1)}
      >
        -
      </div>
      <div className="w-30 border border-color-3 text-center line-height-20">{number}</div>
      <div
        className={`w-30 border-right border-top border-bottom  border-radius-tr-4 border-radius-br-4 font-size-16 ${
          number >= max ? 'border-color-9 text-color-9' : 'border-color-3'
        } text-center`}
        onClick={() => handleChange(1)}
      >
        +
      </div>
    </div>
  );
}
