import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetLoginCustomerUrl } from '../../api';
import AppBar from '../../components/appBar';
import Page from '../../components/page';
import main from '../../main';
export const _customerUrl = 'https://work.weixin.qq.com/kfid/kfc6799b3ca6f22f1a8';

export default function CustomerPage() {
  const navigate = useNavigate();
  const [list, setList] = useState<CustomServiceUrl[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadCustomer();
  }, []);

  async function loadCustomer() {
    try {
      let res = await apiGetLoginCustomerUrl();
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
      }
    } catch (e) {
    } finally {
      setLoaded(true);
    }
  }

  let useUrl = main.customer || _customerUrl;
  let find = false;
  if (list.length && main.currentEm?.tagNameList?.length) {
    for (let userTag of main.currentEm.tagNameList!) {
      for (let customUrl of list) {
        if (customUrl.tagNameList && customUrl.tagNameList.includes(userTag)) {
          useUrl = customUrl.serviceUrl!;
          find = true;
          break;
        }
      }
      if (find) break;
    }
  }

  if (list.length && useUrl?.includes('work.weixin.qq.com')) {
    window.location.href = useUrl;
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1" appBar={<AppBar title="在线客服" />}>
      {loaded ? (
        <iframe className="bw-100 bh-100 border-none" src={useUrl} />
      ) : (
        <div className="d-flex-r bh-100 ai-center jc-center">正在加载...</div>
      )}
    </Page>
  );
}
