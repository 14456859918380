import React, { useEffect, useState } from 'react';
import { PayType } from '../../utils/dict';
import RadioBox from '../../components/radioBox';
import Button from '../../components/button';
interface Props {
  payType?: string;
  payTypeList: PayTypeConfig[];
  onOk: (v: any) => void;
  reduction: {
    [x: string]: {
      reductionAmount: string;
      payType: string;
      payCount: number;
      reduction: 'first' | 'all' | 'none';
    };
  };
}
export default function ActivityPaySheet({ payTypeList, payType, onOk, reduction }: Props) {
  const [selectPayType, setPayType] = useState(payType);
  useEffect(() => {
    if (!selectPayType && payTypeList.length > 0) {
      setPayType(payTypeList[0].payType);
    }
  }, []);
  return (
    <div className="bg-white border-radius-tl-10 border-radius-tr-10">
      <div className="p-10 font-widget-600 bw-100  d-flex-r ai-center">
        <img className="icon-20 view-opacity-0" src="/pic/icon_close_black.png" alt="" />
        <div className="flex-1 text-center">支付方式</div>
        <img className="icon-20" src="/pic/icon_close_black.png" alt="" onClick={() => onOk(0)} />
      </div>
      <div className="minh-200">
        {payTypeList.map((i, idx) => (
          <div key={i.id! + idx} className="d-flex-r ai-center p-20" onClick={() => onOk(i.payType)}>
            <img className="icon-20 mr-10" src={`/pic/${PayType.typeIcon(i.payType)}`} alt="" />
            <div className="flex-1">{i.name}</div>
            <RadioBox value={i.payType === payType} onChange={() => onOk(i.payType)} />
          </div>
        ))}
      </div>
      <div className="p-20">
        <Button type="primary" size="md" shape="circle" long onClick={() => onOk(payType)}>
          立即支付
        </Button>
      </div>
    </div>
  );
}
