import React from 'react';
interface Props {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: 'primary' | 'default' | 'danger' | 'warning' | 'gray' | 'origin-red' | 'green';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  long?: boolean;
  shape?: 'circle' | 'round';
  outline?: boolean;
}
export default function Button({ className, onClick, children, disabled, type, size, shape, outline, long }: Props) {
  return (
    <div
      className={`${className} button ${type ?? 'default'} ${size ?? 'sm'} ${shape ?? 'round'} ${
        outline ? 'outline' : ''
      } ${long ? 'long' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={!disabled && onClick ? onClick : undefined}
    >
      {children}
    </div>
  );
}
