import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage';
import LoginPage from './pages/loginPage';
import MainPage from './pages/mainPage';
import PackOrderListPage from './pages/packOrderListPage';
import SelectPackPage from './pages/selectPackPage';
import SwitchNetworkPage from './pages/switchNetworkPage';
import SettingPage from './pages/setting/settingPage';
import EmInfoPage from './pages/setting/emInfoPage';
import IncomeListPage from './pages/incomeListPage';
import RechargePage from './pages/rechargePage';
import RechargeSuccessPage from './pages/rechargeSuccessPage';
import RealNamePage from './pages/realNamePage';
import EmListPage from './pages/emList/emListPage';
import AddEmPage from './pages/emList/addEmPage';
import RemoveEmPage from './pages/emList/removeEmPage';
import ProtocolPage from './pages/setting/protocolPage';
import PackOrderConfirmPage from './pages/packOrderConfirmPage';
import CustomerPage from './pages/customerPage';
import SetPasswordPage from './pages/setting/setPasswordPage';
import CouponListPage from './pages/couponListPage';
import ComplaintIndexPage from './pages/complaint/complaintIndexPage';
import ComplaintPage from './pages/complaint/complaintPage';
import ComplaintListPage from './pages/complaint/complaintListPage';
import ComplaintDetailPage from './pages/complaint/complaintDetailPage';
import ActivityPage from './pages/activityPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="pack-order-list" element={<PackOrderListPage />} />
          <Route path="select-pack" element={<SelectPackPage />} />
          <Route path="switch-network" element={<SwitchNetworkPage />} />
          <Route path="income-list" element={<IncomeListPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="em-info" element={<EmInfoPage />} />
          <Route path="recharge" element={<RechargePage />} />
          <Route path="recharge-success" element={<RechargeSuccessPage />} />
          <Route path="real-name" element={<RealNamePage />} />
          <Route path="em-list" element={<EmListPage />} />
          <Route path="add-em" element={<AddEmPage />} />
          <Route path="remove-em" element={<RemoveEmPage />} />
          <Route path="protocol" element={<ProtocolPage />} />
          <Route path="pack-order-confirm" element={<PackOrderConfirmPage />} />
          <Route path="set-password" element={<SetPasswordPage />} />
          <Route path="coupon-list" element={<CouponListPage />} />
          <Route path="complaint-index" element={<ComplaintIndexPage />} />
          <Route path="complaint" element={<ComplaintPage />} />
          <Route path="complaint-list" element={<ComplaintListPage />} />
          <Route path="complaint-detail" element={<ComplaintDetailPage />} />
          <Route path="activity" element={<ActivityPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
