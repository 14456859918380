import { useEffect, useState } from 'react';
import { apiGetCardList, apiSwitchingNetwork } from '../../api';
import AppBar from '../../components/appBar';
import NoData from '../../components/noData';
import Page from '../../components/page';
import main from '../../main';
import { OperatorType } from '../../utils/dict';
import { copyToClipboard, LoadingMask, showBottom, showConfirm, showToast } from '../../utils/utils';
import RealNameDialog from '../realNamePage/realNameDialog';
import { useNavigate } from 'react-router-dom';

export default function SwitchNetWorkPage() {
  const navigate = useNavigate();
  const [list, setList] = useState<EmCard[]>([]);
  useEffect(() => {
    loadList();
  }, []);

  async function loadList() {
    LoadingMask.show('正在加载...');
    try {
      let res = await apiGetCardList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];
        let _isRecommendList = _list.filter((item: EmCard) => item.isRecommend === 'Y');
        let _notRecommendList = _list.filter((item: EmCard) => item.isRecommend !== 'Y');
        setList([].concat(_isRecommendList, _notRecommendList));
      } else {
        showToast(data.msg ?? '获取卡列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取卡列表失败');
    } finally {
      LoadingMask.hide();
    }
  }
  async function handleRealName(item: any) {
    if (!main.currentEm?.packDetailId || !main.currentEm?.endEffectTime) {
      let modalRes = await showConfirm({
        title: '提示',
        content: '请购买套餐后实名认证',
        cancelBtnText: '取消',
        confirmBtnText: '前往购买',
      });
      if (!modalRes) return;
      navigate('/select-pack');
      return;
    }
    copyToClipboard(item?.iccid!, () => showToast('ICCID已复制到剪切板'));
    showBottom((onOk) => <RealNameDialog onOk={onOk} card={item} />);
  }
  // 切换网络
  async function handleChange(item: EmCard) {
    if (item?.isCurrent === 'Y') {
      showToast('已经是当前网络');
      return;
    }
    let modalRes = await showConfirm({
      title: '提示',
      content: `确定将当前网络切换为【${OperatorType.getTypeStr(item.operatorType)}】`,
      cancelBtnText: '取消',
      confirmBtnText: '确定',
    });
    if (!modalRes) return;
    LoadingMask.show('正在切换...');
    try {
      let res = await apiSwitchingNetwork({ cardId: item.id });
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '切换网络成功');
        loadList();
      } else {
        showToast(data.msg ?? '切换网络失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '切换网络失败');
    } finally {
      LoadingMask.hide();
    }
  }
  // 禅道1763 需要开启实名按钮的
  let enableRealNameList: string[] = [];
  // 是否有推荐的未实名的
  let hasRecommend = false;
  for (let item of list) {
    // // 实名且推荐
    // item.realStatus === 'Y' && item.isRecommend === 'Y'
    // // 实名 未推荐
    // item.realStatus === 'Y' && item.isRecommend !== 'Y'
    // // 未实名且推荐
    // item.realStatus !== 'Y' && item.isRecommend === 'Y'
    // // 未实名且未推荐
    // item.realStatus !== 'Y' && item.isRecommend !== 'Y'

    if (item.realStatus !== 'Y' && item.isRecommend === 'Y') {
      // 找到第一个未实名并且是推荐的 只显示一个启用实名按钮的 剩下的全关闭
      enableRealNameList.push(item.id);
      hasRecommend = true;
      break;
    }
  }
  if (!hasRecommend) {
    // 没有 未实名且推荐的，则说明推荐的都实名了或没有推荐的   则开启所有未实名且未推荐的
    for (let item of list) {
      if (item.realStatus !== 'Y') {
        enableRealNameList.push(item.id);
      }
    }
  }
  // 禅道1799 只展示实名和的可实名的 剩余的不展示
  let _list: EmCard[] = [];
  for (let item of list) {
    if (item.realStatus === 'Y' || enableRealNameList.includes(item.id) || item.isRealReveal === 'Y') {
      _list.push(item);
    }
  }
  return (
    <Page
      className="d-flex-c bg-gray"
      contentClassName="bg-white flex-1 d-flex-c h-1"
      appBar={<AppBar title="网络情况" />}
    >
      <div className="scrolling-text bg-p text-color-white">
        <span>切换网络会导致网络波动，建议切换卡片后，插拔设备，能更快的更换网络</span>
      </div>
      <div>
        {!_list.length && <NoData tips="暂无卡号" />}
        {_list.map((item, index) => (
          <div
            key={index}
            className="d-flex-r ai-center jc-between p-20 bg-white border-bottom h-60"
            onClick={
              item.realStatus === 'Y'
                ? () => {
                    handleChange(item);
                  }
                : undefined
            }
          >
            <div className="flex-1 d-flex-r ai-center">
              <img className="w-24 mr-10" src={`/pic/${OperatorType.getTypeIcon(item.operatorType)}.png`} alt="" />
              <span>{OperatorType.getTypeStr(item.operatorType)}</span>
              {item.realStatus === 'Y' && <div className={`ml-10 w-8 h-8 border-radius-harf bg-green`}></div>}
              {item.realStatus === 'Y' && <span className="ml-5">已实名</span>}
              {item.isRecommend === 'Y' && <img className="w-70 ml-10" src={`/pic/icon_recommend.png`} alt="" />}
            </div>
            <div>
              {item.realStatus === 'Y' && item?.isCurrent === 'Y' ? (
                <img className="icon-24" src="/pic/icon_radio_checked.png" alt="" />
              ) : (
                item.realStatus === 'N' && (
                  <span className="text-color-p" onClick={() => handleRealName(item)}>
                    未实名去认证{'>>'}
                  </span>
                )
              )}
              {item.realStatus === 'Y' && _list.filter((i) => i.isCurrent === 'Y').length === 0 && (
                <span className="text-color-p">使用此网络</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="font-size-12 text-color-9 pt-10 pb-10 pl-20 pr-20">
        <div>温馨提示：</div>
        <div>1、切换网络间隔时间30分钟；</div>
        <div>2、切换网络会导致网络波动，建议切换卡片后，插拔设备，能更快的更换网络；</div>
      </div>
    </Page>
  );
}
