interface Props {
  className?: string;
  options: string[];
  optionExpands?: string[];
  value: string;
  onChange: (value: string) => void;
  badges?: (string | number)[];
  type?: 'primary' | 'origin-red';
}
export default function TabBar({
  className,
  options,
  badges,
  value,
  optionExpands,
  onChange,
  type = 'primary',
}: Props) {
  return (
    <div className={`tab-bar-block ${type} ${className}`}>
      {options.map((i, idx) => (
        <div key={i} className={`tab-bar-item ${i === value ? 'active' : ''} pos-r`} onClick={() => onChange(i)}>
          <div className="label">
            {i}
            {(optionExpands ?? []).length > idx ? optionExpands![idx] : ''}
          </div>
          <div className="active-line" />
          {!!badges?.[idx] && (
            <div className="pos-a r-5 t-5 h-14 minw-14 pl-2 pr-2 bg-red border-radius-20 text-color-white font-size-12">
              {badges![idx]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
