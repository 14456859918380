import React from 'react';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
export default function ComplaintIndexPage() {
  const navigate = useNavigate();
  return (
    <Page className="d-flex-c bg-white" contentClassName="flex-1" appBar={<AppBar title="投诉" />}>
      <div className="m-20 pb-10 border-bottom font-size-18 font-widget-600">投诉举报</div>
      <div className="ml-20 mr-20">
        若您遇到其他无法和商家沟通解决的问题，您可点击下方“投诉商家”，我们会协助您解决问题。
      </div>
      <div className="mt-30 mb-40 d-flex-c ai-center">
        <Button type="green" size="md" onClick={() => navigate('/complaint')}>
          <div className="pl-20 pr-20">投诉商家</div>
        </Button>
      </div>
      <div
        className="border-top border-bottom pt-10 pb-10 pl-20 pr-20 d-flex-r ai-center"
        onClick={() => navigate('/complaint-list')}
      >
        <div className="flex-1">投诉历史</div>
        <img className="icon-16" src="/pic/icon_arrow_right.png" alt="" />
      </div>
    </Page>
  );
}
