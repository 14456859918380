import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiGetComplaintDetail } from '../../../api';
import AppBar from '../../../components/appBar';
import Page from '../../../components/page';
import { ComplaintStatus } from '../../../utils/dict';
import { showToast } from '../../../utils/utils';
export default function ComplaintDetailPage() {
  const navigate = useNavigate();
  const { state: id } = useLocation();
  const [detailData, setdetailData] = useState<Complaint>({});
  useEffect(() => {
    getComplaintDetail();
  }, []); // eslint-disable-line
  const getComplaintDetail = async () => {
    try {
      var res = await apiGetComplaintDetail(id);
      var data = res.data;
      if (data.code === 200) {
        setdetailData(data.data);
      } else {
        showToast(data.msg ?? '请求失败');
      }
    } catch (e: any) {
      showToast(e.message || '请求失败');
    }
  };
  return (
    <Page
      className="d-flex-c bg-gray"
      contentClassName="flex-1 overflow-y h-1 p-20"
      appBar={<AppBar title="投诉历史详情" className="bg-white" />}
    >
      <div className="p-10 bg-white border-radius-8 text-color-0">
        <div className="d-flex-r jc-between mb-10">
          <span className="w-60 text-color-6">投诉时间</span>
          <div>{detailData?.createTime}</div>
        </div>
        <div className="d-flex-r jc-between mb-10">
          <span className="w-60 text-color-6">设备号</span>
          <div>{detailData?.emNumber}</div>
        </div>
        <div className="d-flex-r jc-between mb-10">
          <span className="w-60 text-color-6">投诉类型</span>
          <div className="flex-1 ml-20 text-right">{detailData?.workOrderName}</div>
        </div>
        <div className="d-flex-r jc-between mb-10">
          <span className="w-60 text-color-6">投诉原因</span>
          <div className="flex-1 ml-20 d-flex-c text-right">
            <div className="mb-10">{detailData?.workOrderContent}</div>
            <div>
              {!!detailData?.workOrderImg &&
                detailData?.workOrderImg
                  .split(';')
                  .map((i: string, idx: number) => <img key={idx} className="icon-40 ml-10" src={i} alt="" />)}
            </div>
          </div>
        </div>
        <div className="d-flex-r jc-between">
          <span className="w-60 text-color-6">处理状态</span>
          <div>{ComplaintStatus.getTypeStr(detailData?.status)}</div>
        </div>
      </div>
      {detailData?.status !== 'wait' && (
        <div className="mt-20 p-10 bg-white border-radius-8 text-color-0">
          <div className="d-flex-r jc-between mb-10">
            <span className="w-60 text-color-6">处理时间</span>
            <div>{detailData?.updateTime}</div>
          </div>
          <div className="d-flex-r jc-between">
            <span className="w-60 text-color-6">处理结果</span>
            <div className="flex-1 ml-20 d-flex-c text-right">
              <div className="mb-10">{detailData?.dealResult}</div>
              <div>
                {!!detailData?.dealImg &&
                  detailData?.dealImg
                    .split(';')
                    .map((i: string, idx: number) => <img key={idx} className="icon-40 ml-10" src={i} alt="" />)}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex-r ai-center jc-center mt-20 text-color-0">
        问题未解决?
        <span className="ml-5 text-color-p" onClick={() => navigate('/customer')}>
          联系客服
        </span>
      </div>
    </Page>
  );
}
