import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { apiGetRealUrl, apiGetWeChatSignature, apiUpdateRealTime } from '../../api';
import main from '../../main';
import { OperatorType } from '../../utils/dict';
import { copyToClipboard, showConfirm, showToast } from '../../utils/utils';

const ydRealNameStep = [
  {
    pic: '/pic/pic_real_name_m_1.png',
    title: '上传身份证人像面',
  },
  {
    pic: '/pic/pic_real_name_m_2.png',
    title: '上传身份证国徽面',
  },
  {
    pic: '/pic/pic_real_name_m_3.png',
    title: '按照要求拍视频并上传',
  },
  {
    pic: '/pic/pic_real_name_m_4.png',
    title: '实名状态更新有30分钟延迟,请勿重复提交',
  },
];
const ltRealNameStep = [
  {
    pic: '/pic/pic_real_name_1.png',
    title: '点击去实名跳转联通小程序,点击确认登录',
  },
  {
    pic: '/pic/pic_real_name_2.png',
    title: '点击开始认证',
  },
  {
    pic: '/pic/pic_real_name_3.png',
    title: '上传身份证正面',
  },
  {
    pic: '/pic/pic_real_name_4.png',
    title: '上传身份证反面',
  },
  {
    pic: '/pic/pic_real_name_5.png',
    title: '进行人脸识别,完成后点击下一步',
  },
  {
    pic: '/pic/pic_real_name_6.png',
    title: '进行签名认证,完成后点击提交',
  },
  {
    pic: '/pic/pic_real_name_7.png',
    title: '实名状态更新有30分钟延迟,请勿重复提交',
  },
];
const dxRealNameStep = [
  {
    pic: '/pic/pic_real_name_d_1.jpg',
    title: '填写ICCID，手机号，验证码',
  },
  {
    pic: '/pic/pic_real_name_d_2.jpg',
    title: '上传身份证正反面',
  },
  {
    pic: '/pic/pic_real_name_d_3.jpg',
    title: '拍视频认证',
  },
  {
    pic: '/pic/pic_real_name_d_4.jpg',
    title: '认证成功',
  },
];
const gdRealNameStep = [
  {
    pic: '/pic/pic_real_name_gd_1.png',
    title: '登录中国广电营业厅',
  },
  {
    pic: '/pic/pic_real_name_gd_2.png',
    title: '两次输入ICCID后点击确认按钮',
  },
  {
    pic: '/pic/pic_real_name_gd_3.png',
    title: '上传身份证正反面',
  },
  {
    pic: '/pic/pic_real_name_gd_4.png',
    title: '人脸识别前做好准备',
  },
  {
    pic: '/pic/pic_real_name_gd_5.png',
    title: '认证成功',
  },
];

// // 广电实名链接
// const guangdianURL = 'https://m.10099.com.cn/h5wap/busiClient/activateIOTCard/authenticationIccId.html';
// // 电信实名链接
// const dianxinUrl =
//   'http://uniteapp.ctwing.cn:9090/uapp/certifhtml/certif_entry.html?userCode=nGmFFq6z4WZxGKIgPw+jBw==&passWord=97xjIqkYSeyG6wc5YiqGjA==';

interface Props {
  card: EmCard;
  onOk: (value: any) => void;
}
export default function RealNameDialog({ card, onOk }: Props) {
  const [url, setUrl] = useState('');
  const [btn, setBtn] = useState<any>();
  const wxBtnRef = useRef<any>();
  useEffect(() => {
    if (card.operatorType === OperatorType.lianTong) {
      wxConfig();
    } else {
      getYDUrl();
    }
    window.addEventListener('popstate', handlePopstate);
  }, []); //eslint-disable-line

  function handlePopstate() {
    onOk(0);
    window.removeEventListener('popstate', handlePopstate);
  }

  function handleRef(e: any) {
    wxBtnRef.current = e;
    wxBtnRef.current?.removeEventListener('ready', readyHandel);
    wxBtnRef.current?.removeEventListener('launch', launchHandel);
    wxBtnRef.current?.removeEventListener('error', errorHandel);
    wxBtnRef.current?.addEventListener('ready', readyHandel);
    wxBtnRef.current?.addEventListener('launch', launchHandel);
    wxBtnRef.current?.addEventListener('error', errorHandel);
  }

  async function launchHandel(e: any) {
    console.log('跳转小程序成功：', e.detail);
    onOk(1);
    updateRealTime();
  }

  function errorHandel(e: any) {
    console.log('跳转小程序失败：', e.detail);
  }

  function readyHandel() {
    console.log('跳转小程序准备完成');
  }
  // 实名跳转确认
  async function updateRealTime() {
    try {
      await apiUpdateRealTime(card.id);
    } catch (e) {}
  }

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;

    wx.ready(() => {
      var iccid = card.msisdn!.substring(0, card.msisdn!.length - 1);
      const wxBtn = (
        // @ts-ignore
        <wx-open-launch-weapp
          ref={handleRef}
          id="launch-btn"
          class="wx-btn"
          username={main.unionMiniprogram}
          path={`pages/login/index?iccid=${iccid}`}
        >
          <script type="text/wxtag-template">
            <div
              style={{ width: '100vw', height: '12.8vw', background: 'transparent', display: 'block', border: 'none' }}
            />
          </script>
          {/* @ts-ignore */}
        </wx-open-launch-weapp>
      );
      setBtn(wxBtn);
    });

    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;
    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: [], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'],
      });
    }
  }
  async function getYDUrl() {
    var _url = '';
    try {
      var res = await apiGetRealUrl(card.id);
      var data = res.data;
      if (data['code'].toString() === '200') {
        _url = data['data'];
      } else {
        _url = data['msg'] ?? '官方系统升级，暂时无法获取';
        showToast(_url);
      }
    } catch (e: any) {
      _url = '官方系统升级，暂时无法获取';
      showToast(_url);
    }
    setUrl(_url);
  }

  function handleRealName(jump: boolean) {
    if (!url || !url.startsWith('http')) {
      showConfirm({
        title: '提示',
        content: url || '官方系统升级，暂时无法获取实名链接',
        hideCancelBtn: true,
        confirmBtnText: '知道了',
      });
    } else {
      copyToClipboard(url);
      updateRealTime();
      if (jump) {
        window.open(url);
      }
    }
  }

  let tips: any[] = [];
  tips = gdRealNameStep;
  if (card.operatorType === OperatorType.guangDian) {
    tips = gdRealNameStep;
  } else if (card.operatorType === OperatorType.yiDong) {
    tips = ydRealNameStep;
  } else if (card.operatorType === OperatorType.lianTong) {
    tips = ltRealNameStep;
  } else if (card.operatorType === OperatorType.dianXin) {
    tips = dxRealNameStep;
  }

  return (
    <div className="real-name-dialog">
      <div className="text-right pr-10 mb-20">
        <img className="icon-24" src="/pic/icon_close_white.png" alt="" onClick={() => onOk(0)} />
      </div>
      <div className="step-img-block bg-white border-radius-10 d-flex-c">
        <div className="font-size-16 pl-10 pt-10">实名流程</div>
        <Slider autoplay>
          {tips.map((item, idx: number) => (
            <div key={item.pic + idx} className="slider-img-block">
              <div className="font-14 h-40 mt-10 mb-10">
                {item.title.split(',').map((item: any, index: number) => (
                  <div key={item + index}>
                    {item}
                    <br />
                  </div>
                ))}
              </div>
              <img src={item.pic} alt="" className="slider-img" />
              <div className="slider-index">
                {idx + 1} / {tips.length}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="bg-white border-radius-tr-10 border-radius-tl-10 mt-20">
        {OperatorType.yiDong === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => handleRealName(false)}
          >
            第一步：复制链接
          </div>
        )}
        {OperatorType.yiDong === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => {
              onOk(1);
              handleRealName(true);
            }}
          >
            第二步：去实名
          </div>
        )}

        {OperatorType.lianTong === card.operatorType && (
          <div className="h-60 line-height-60 text-center border-bottom pos-r text-color-p font-size-16">
            {btn}去实名
          </div>
        )}
        {OperatorType.dianXin === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => {
              copyToClipboard(card.iccid);
            }}
          >
            第一步：复制卡号
          </div>
        )}
        {OperatorType.dianXin === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => {
              onOk(1);
              handleRealName(true);
            }}
          >
            第二步：去实名
          </div>
        )}

        {OperatorType.dianXin === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => handleRealName(false)}
          >
            复制链接
          </div>
        )}
        {OperatorType.guangDian === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => handleRealName(false)}
          >
            第一步：复制链接
          </div>
        )}
        {OperatorType.guangDian === card.operatorType && (
          <div
            className="h-60 line-height-60 text-center border-bottom text-color-p font-size-16"
            onClick={() => {
              onOk(1);
              handleRealName(true);
            }}
          >
            第二步：去实名
          </div>
        )}

        <div className="h-40 line-height-40 text-center border-bottom" onClick={() => onOk(0)}>
          取消
        </div>
      </div>
    </div>
  );
}
