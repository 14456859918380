import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiGetCardList } from '../../api';
import AppBar from '../../components/appBar';
import NoData from '../../components/noData';
import Page from '../../components/page';
import main from '../../main';
import { OperatorType } from '../../utils/dict';
import { copyToClipboard, LoadingMask, showBottom, showToast } from '../../utils/utils';
import RealNameDialog from './realNameDialog';
import Button from '../../components/button';

export default function RealNamePage() {
  const [list, setList] = useState<EmCard[]>([]);
  const location = useLocation();
  const { id, open } = location.state ?? {};
  useEffect(() => {
    loadList(true);
  }, []); // eslint-disable-line
  // checkOpen 从支付完成页面跳进来会自动打开 点页面内刷新按钮就不用再打开了
  async function loadList(checkOpen?: boolean) {
    LoadingMask.show('正在加载...');
    try {
      let res = await apiGetCardList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];
        let _isRecommendList = _list.filter((item: EmCard) => item.isRecommend === 'Y');
        let _notRecommendList = _list.filter((item: EmCard) => item.isRecommend !== 'Y');
        setList([].concat(_isRecommendList, _notRecommendList));
        if (checkOpen && open && id) {
          let _card = _list.find((item: EmCard) => item.id === id);
          if (_card) {
            handleRealName(_card);
          }
        }
      } else {
        showToast(data.msg ?? '获取卡列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取卡列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleRealName(item: any) {
    copyToClipboard(item?.iccid!, () => showToast('ICCID已复制到剪切板'));
    let res = await showBottom((onOk) => <RealNameDialog onOk={onOk} card={item} />);
    if (res) {
      setTimeout(() => {
        loadList();
      }, 1000 * 60);
    }
  }

  // 需要开启实名按钮的
  let enableRealNameList: string[] = [];
  // 是否有推荐的未实名的
  let hasRecommend = false;
  for (let item of list) {
    // // 实名且推荐
    // item.realStatus === 'Y' && item.isRecommend === 'Y'
    // // 实名 未推荐
    // item.realStatus === 'Y' && item.isRecommend !== 'Y'
    // // 未实名且推荐
    // item.realStatus !== 'Y' && item.isRecommend === 'Y'
    // // 未实名且未推荐
    // item.realStatus !== 'Y' && item.isRecommend !== 'Y'

    if (item.realStatus !== 'Y' && item.isRecommend === 'Y') {
      // 找到第一个未实名并且是推荐的 只显示一个启用实名按钮的 剩下的全关闭
      enableRealNameList.push(item.id);
      hasRecommend = true;
      break;
    }
  }
  if (!hasRecommend) {
    // 没有 未实名且推荐的，则说明推荐的都实名了或没有推荐的   则开启所有未实名且未推荐的
    for (let item of list) {
      if (item.realStatus !== 'Y') {
        enableRealNameList.push(item.id);
      }
    }
  }

  // 禅道1799 只展示实名和可实名的和后台强制开启的 后台关闭的但上边计算要开的也展示[1976] 剩余的不展示
  let _list: EmCard[] = [];
  for (let item of list) {
    if (item.realStatus === 'Y' || enableRealNameList.includes(item.id) || item.isRealReveal === 'Y') {
      _list.push(item);
    }
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="实名认证" />}>
      <div className="pt-10 pb-10 pl-20 pr-20 bg-red-t2 d-flex-r ai-center">
        <div className="flex-1 text-color-red">若您已实名，请点击刷新获取最新实名结果</div>
        <Button className="border border-color-red" type="default" size="xs" onClick={loadList}>
          <div className="text-color-red">刷新</div>
        </Button>
      </div>

      <div className="h-10 bg-gray"></div>
      <div className="bg-white font-size-16 font-widget-600 p-10 border-bottom">设备号:{main.currentEm?.emNumber}</div>
      <div className="flex-1 overflow-y">
        {!_list.length && <NoData tips="暂无卡号" />}
        {_list.map((item, index) => (
          <div key={index} className="d-flex-r ai-center jc-between p-20 bg-white border-bottom h-60">
            <img className="w-24 mr-10" src={`/pic/${OperatorType.getTypeIcon(item.operatorType)}.png`} alt="" />
            <span>{OperatorType.getTypeStr(item.operatorType)}</span>
            {item.isRecommend === 'Y' && <img className="w-70 ml-10" src={`/pic/icon_recommend.png`} alt="" />}
            <span className="flex-1"></span>
            {item.realStatus === 'Y' && <div className="ml-10 w-8 h-8 border-radius-harf bg-green"></div>}
            {item.realStatus === 'Y' && <span className="ml-5">已实名</span>}
            {item.realStatus === 'N' && (
              <span className="text-color-p" onClick={() => handleRealName(item)}>
                未实名去认证{'>>'}
              </span>
            )}
          </div>
        ))}
        {!!_list.length && (
          <div className="bg-white font-size-12 text-color-9 pt-10 pl-20 pr-20">
            <div>温馨提示</div>
            <div>各个运营商的实名流程如下：</div>
            <div>1、填写卡号(电信、广电需要提前复制卡号)</div>
            <div>2、上传身份证正反面，请提前准备好相关证件，在拍照过程中保证无反光和水印，保证文字清晰可见。</div>
            <div>3、活体认证。(阅读数字或完成指定动作)</div>
            <div>4、激活完成。</div>
            <div>5、激活结果可能存在延时，若已经实名但结果未更新，请稍后再次进行查询。</div>
            <div>注意：各运营商平台实名过程略有差异，请按照截图提示操作。</div>
          </div>
        )}
      </div>
    </Page>
  );
}
