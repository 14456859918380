import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '../../components/appBar';
import Page from '../../components/page';
import { useEffect, useRef, useState } from 'react';
import main from '../../main';
import { apiGetCardList, apiGetRealCount, apiGetWeChatSignature, apiUpdateRealTime } from '../../api';
import { OperatorType } from '../../utils/dict';

export default function RechargeSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const [btn, setBtn] = useState<any>();
  const wxBtnRef = useRef<any>();
  const [card, setCard] = useState<EmCard>();
  let { name, type, startTime, endTime } = location.state ?? {};

  useEffect(() => {
    // 有时间说明是买套餐
    if (startTime) {
      loadRealCount(main.currentEm?.id!);
    }
  }, []); // eslint-disable-line
  async function loadRealCount(emId: string) {
    try {
      let res = await apiGetRealCount(emId);
      let data = res.data;
      if (data.code === 200) {
        if (data.data.realNameY <= 0) {
          // 没有实名的
          let cres = await apiGetCardList(emId);
          let cdata = cres.data;
          if (cdata.code === 200) {
            let _list = cdata.data ?? [];
            let _isRecommendList = _list.filter((item: EmCard) => item.isRecommend === 'Y');
            let _notRecommendList = _list.filter((item: EmCard) => item.isRecommend !== 'Y');
            _list = [].concat(_isRecommendList, _notRecommendList);
            let card: EmCard = _list.find((i: EmCard) => i.realStatus === 'N');
            if (card) {
              setCard(card);
              if (card.operatorType === OperatorType.lianTong) {
                wxConfig(card);
              }
            }
          }
        }
      }
    } catch (e: any) {}
  }
  function handleRef(e: any) {
    wxBtnRef.current = e;
    wxBtnRef.current?.removeEventListener('ready', readyHandel);
    wxBtnRef.current?.removeEventListener('launch', launchHandel);
    wxBtnRef.current?.removeEventListener('error', errorHandel);
    wxBtnRef.current?.addEventListener('ready', readyHandel);
    wxBtnRef.current?.addEventListener('launch', launchHandel);
    wxBtnRef.current?.addEventListener('error', errorHandel);
  }

  async function launchHandel(e: any) {
    console.log('跳转小程序成功：', e.detail);
    navigate(-1);
    updateRealTime();
  }

  // 实名跳转确认
  async function updateRealTime() {
    try {
      await apiUpdateRealTime(card!.id);
    } catch (e) {}
  }

  function errorHandel(e: any) {
    console.log('跳转小程序失败：', e.detail);
  }

  function readyHandel() {
    console.log('跳转小程序准备完成');
  }

  async function wxConfig(card: EmCard) {
    // @ts-ignore
    let wx = window.wx;

    wx.ready(() => {
      var iccid = card.msisdn!.substring(0, card.msisdn!.length - 1);
      const wxBtn = (
        // @ts-ignore
        <wx-open-launch-weapp
          ref={handleRef}
          id="launch-btn"
          class="wx-btn"
          username={main.unionMiniprogram}
          path={`pages/login/index?iccid=${iccid}`}
        >
          <script type="text/wxtag-template">
            <div
              style={{ width: '100vw', height: '12.8vw', background: 'transparent', display: 'block', border: 'none' }}
            />
          </script>
          {/* @ts-ignore */}
        </wx-open-launch-weapp>
      );
      setBtn(wxBtn);
    });

    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;
    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: [], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'],
      });
    }
  }

  return (
    <Page
      className="d-flex-c bg-gray"
      contentClassName="flex-1 d-flex-c h-1"
      appBar={<AppBar title="" onBack={() => navigate('/home')} />}
    >
      <div className="bw-100 bh-100 flex-1 bg-white d-flex-c ai-center">
        <div className="font-widget-600 d-flex-r ai-center font-size-20 mt-160 mb-20">
          <img className="icon-20 mr-10" src="/pic/icon_recharge_success.png" alt="" />
          <span>支付成功</span>
        </div>

        {!!name && (
          <div className="mb-20">
            {!!name && (
              <div>
                <span className="text-color-9">订单名称:</span>
                {name}
              </div>
            )}
            {!!type && (
              <div>
                <span className="text-color-9">订单类型:</span>
                {type}
              </div>
            )}
            {!!startTime && !!endTime && (
              <div>
                <span className="text-color-9">有效期:</span>
                <div>
                  {startTime}至{endTime}
                </div>
              </div>
            )}
          </div>
        )}
        {card?.operatorType === OperatorType.lianTong && (
          <div className="pl-24 pr-24 pt-12 pb-12 w-120 border-radius-4 text-center bg-p text-color-white mb-20 font-size-16 pos-r">
            {btn}去实名
          </div>
        )}
        {!!card && card.operatorType !== OperatorType.lianTong && (
          <div
            className="pl-24 pr-24 pt-12 pb-12 w-120 border-radius-4 bg-p text-center text-color-white mb-20 font-size-16"
            onClick={() => {
              console.log('use replace');
              navigate('/real-name', { replace: true, state: { id: card.id, open: true } });
            }}
          >
            去实名
          </div>
        )}
        {!card && (
          <div
            className="pl-24 pr-24 pt-12 pb-12 w-120 border-radius-4 bg-p text-center text-color-white mb-20 font-size-16"
            onClick={() => navigate('/home', { replace: true })}
          >
            返回首页
          </div>
        )}
      </div>
    </Page>
  );
}
