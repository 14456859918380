import { useEffect, useState } from 'react';
import { apiGetEmList, apiRemoveEm } from '../../../api';
import AppBar from '../../../components/appBar';
import Button from '../../../components/button';
import NoData from '../../../components/noData';
import Page from '../../../components/page';
import { LoadingMask, showBottom, showConfirm, showToast } from '../../../utils/utils';

export default function RemoveEmPage() {
  const [selected, setSelected] = useState('');
  const [list, setList] = useState<EM[]>([]);
  useEffect(() => {
    loadEmList();
  }, []); // eslint-disable-line

  async function loadEmList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetEmList();
      let data = res.data;
      if (data.code === 200) {
        setList(data.data ?? []);
      } else {
        showToast(data.msg ?? '加载列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '加载列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleSelectEm() {
    let selectRes = await showBottom((onOk) => <RemoveEmDialog onOk={onOk} select={selected} list={list} />);
    if (!selectRes) return;
    setSelected(selectRes);
  }

  async function handleUnbind() {
    if (!selected) {
      showToast('请选择要解绑的设备');
      return;
    }
    let modalRes = await showConfirm({
      title: '解绑设备',
      content: `确定要解绑设备？`,
    });
    if (!modalRes) return;
    try {
      LoadingMask.show('解绑中...');
      let res = await apiRemoveEm(selected);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '解绑成功');
        loadEmList();
      } else {
        showToast(data.msg ?? '解绑失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '解绑失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let selectEmNumber = list.find((item) => item.id === selected)?.emNumber;
  return (
    <Page appBar={<AppBar title="选择设备" />}>
      <div className="h-10 bg-gray"></div>
      <div className="bg-white font-size-16 font-widget-600 p-10 border-bottom">设备信息</div>
      <div className="d-flex-r border-bottom p-10">
        <div className="w-120">设备号(必填)</div>
        <div className={`flex-1 ${!!selected ? '' : 'text-color-9'}`} onClick={handleSelectEm}>
          {selectEmNumber || '请选择设备'}
        </div>
        <img className="icon-20 ml-10" src="/pic/icon_select_down.png" alt="" />
      </div>
      <div className="pl-20 pr-20 mt-40">
        <Button type="danger" long size="md" onClick={handleUnbind}>
          解绑设备
        </Button>
      </div>
    </Page>
  );
}

interface RemoveEmDialogProps {
  select?: string;
  onOk: (value: unknown) => void;
  list: EM[];
}

function RemoveEmDialog({ onOk, list, select }: RemoveEmDialogProps) {
  const [selected, setSelected] = useState<string | undefined>(select);
  useEffect(() => {
    if (!select) {
      let em = list.find((item) => item.status !== 'Y');
      if (em) {
        setSelected(em.id);
      }
    }
  }, []); // eslint-disable-line

  return (
    <div className="bg-white border-radius-tl-10 border-radius-tr-10 pt-10">
      <div className="d-flex-r ai-center jc-between pl-10 pr-10 pb-10">
        <div onClick={() => onOk(false)}>取消</div>
        <div className="font-size-16">选择设备</div>
        <div className="text-color-p" onClick={() => onOk(selected || false)}>
          确定
        </div>
      </div>
      <div className="h-200 p-10">
        {!list.length && <NoData isPage={false} tips="暂无设备" />}
        {list.map((item) => (
          <div
            key={item.id}
            className="d-flex-r ai-center jc-between text-color-9 p-10"
            onClick={() => {
              if (item.status === 'Y') {
                return showToast('已激活的设备不可解绑');
              }
              setSelected(item.id);
            }}
          >
            <img
              className={`w-24 pr-10 ${selected !== item.id ? 'view-opacity-0' : ''}`}
              src="/pic/icon_select_arrow_right.png"
              alt=""
            />
            <div className={`pr-10 ${selected === item.id ? 'font-size-14 text-color-3' : 'font-size-12'}`}>
              设备号:{item.emNumber}
            </div>
            <div
              className={`d-flex-r ai-center ${selected === item.id ? 'font-size-14 text-color-3' : 'font-size-12'}`}
            >
              <div className={`w-8 h-8 border-radius-harf bg-${item.status === 'Y' ? 'green' : 'red'} mr-10`}></div>
              <div>{item.status === 'Y' ? '已激活' : '未激活'}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
